.sendMessage_header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 31px;
}

.sendMessage_header img {
  width: 31px;
  cursor: pointer;
}
.sendMessage_title {
  font-weight: 700;
  font-size: 20px;
  line-height: 42px;
  color: #282828;
  flex: 1;
  text-align: center;
}

.container {
  padding: 20px;
}

.button_container {
  position: fixed;
  padding-left: 10px;
  padding-right: 10px;
  bottom: 30px;
  right: 0;
  left: 0;
}

.sms_data {
  display: flex;
  justify-content: space-between;
}
