.mobile_form_container {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  padding: 13px;
  margin-top: 15px;
}

.logo_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  position: relative;
  left: 20px;
  top: 100px;
  display: flex;
  /* border: 1px solid #000; */
  font-weight: 700;
  font-size: 30px;
  line-height: 42px;
  color: #282828;
}

.img {
  display: flex;
  justify-content: center;

  /* border: 1px solid #000; */
  /* padding-left: 60px; */
  height: 300px;
  width: auto;
  margin-top: -20px;
}

.button_container {
  position: fixed;
  bottom: 10px;
  left: 0;
  right: 0;
  padding: 20px;
}
