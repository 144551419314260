
.notification_container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff8a35;
  height: 60px;
  font-weight: 700;
  font-size: 20px;
  color: white;
}
