.container {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  row-gap: 40px;
  width: 1266px;
  height: 768px;
  /* border: 1px solid #000; */
}

.inner_container {
  display: flex;
  flex-direction: column;
  row-gap: 13px;
  width: 1366px;
  height: 731px;
  background: #ebf1ff;
  padding-top: 70px;
  padding-right: 38px;
  padding-left: 550px;
  border-radius: 32px;
}

.item {
  display: flex;
  column-gap: 15px;
}

.circle {
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background-color: #000000;
  margin-top: 4px;
}

.title {
  color: #000000;
  font-size: 24px;
  font-weight: 500;
  width: 94%;
}

.img {
  position: absolute;
  left: -80px;
  top: -100px;
  width: 626px;
}
