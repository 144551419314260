.button1 {
  width: 242px;
  height: 70px;
  background: linear-gradient(180deg, #000000 0%, #282828 100%);
  border-radius: 32px;
  color: #ffff;
  font-size: 20px;

  font-family: Comfortaa;
  font-size: 20px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
}

.button1:hover {
  background: linear-gradient(180deg, #373737 0%, #282828 100%);
}

.button2 {
  width: 359px;
  height: 70px;
  background: #ff8a35;
  border-radius: 32px;
  color: #ffff;
  font-size: 20px;
  border: none;

  font-family: Comfortaa;
  font-size: 20px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
}

.button2:hover {
  background: #f8791d;
}

.button3 {
  width: 242px;
  height: 70px;
  background: #ffffff;
  border-radius: 32px;
  font-size: 20px;
  border: none;
  color: #ff6e05;

  font-family: Comfortaa;
  font-size: 20px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
}

.advantage_container {
  width: 340px;
}

.advantage_line1 {
  display: flex;
}

.advantage_line2 {
  padding-top: 10px;
  padding-left: 6px;
  display: flex;
}

.advantage_title {
  /* border: 1px solid #000; */
  display: flex;
  align-items: center;
  padding-left: 20px;
  font-weight: 500;
  font-size: 20px;
}

.block_title {
  font-family: Comfortaa;
  font-size: 36px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0em;
}

.titleM {
  font-size: 18px;
  font-weight: 700;
  /* border: 1px solid #000; */
  text-align: center;

  font-family: Comfortaa;
}

.for_whom_container {
  width: 380px;
  height: 180px;
  border: 1px solid #000000;
  border-radius: 32px;
  padding: 32px;
}

.for_whom_line1 {
  font-weight: 500;
  font-size: 24px;
}

.for_whom_line2 {
  padding-top: 15px;
}

.function_container {
  width: 210px;
  height: 201px;
  border: 1px solid #989a99;
  border-radius: 32px;
  font-weight: 500;
  font-size: 20px;
  color: #989a99;
  padding: 15px;
}

.function_line2 {
  padding-top: 10px;
}

.step_container {
  display: flex;
  width: 954px;
  height: 133px;
  background: #f6f6f6;
  border-radius: 32px;
}

.step_number {
  display: flex;
  align-items: center;
  font-size: 62px;
  font-weight: 700;
  color: #ff6e05;
  padding-left: 25px;
}

.step_title {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 500;
  padding-left: 100px;
  padding-right: 111px;
}

.input {
  font-size: 20px;
  color: white;
  border: 1px solid #ffffff;
  border-radius: 32px;
  width: 350px;
  height: 56px;
  background-color: transparent;
  padding-left: 20px;
  color: #ffffff;
  outline: none;
}

.input::placeholder {
  color: #ffffff;
}
