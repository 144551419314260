.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1266px;
  height: 768px;
  /* border: 1px solid #000; */
  margin-top: 100px;
}

.inner_container {
  display: flex;
  width: 1366px;
  height: 686px;
  left: 0px;
  top: 0px;

  background: #ebf1ff;
  border-radius: 32px;
}

.image {
  /* border: 1px solid rgb(160, 27, 27); */
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 38px;
}

.form {
  /* border: 1px solid #000; */
  padding: 155px 94px 181px 52px;
}

.title {
  font-family: Comfortaa;
  font-size: 36px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: left;

  color: #000;
}

.subtitle {
  padding-top: 30px;
  font-size: 24px;
  font-weight: 400;
  color: #000;
}

.inputs {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}
