.root {
  height: 100vh;
  background-color: #DADADA21;
}

.container {
  width: 100%;
  margin: 25px auto;
  padding-block: 20px;
  max-width: 890px;
  border-radius: 20px;
  background-color: #FFFFFF;
}

.paginateMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  padding-inline: 50px;
}

.paginateMenu .name {
  font-size: 20px;
  font-weight: 500;
}

.paginateMenu .addTransaction {
  width: 172px;
  padding: 10px 8px;
  background-color: #F4F5F7;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  
}

.tabContainer {
  display: flex;
  gap: 15px;
}

.tab {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  cursor: pointer;
}

.activeTab {
  color: black;
}

.inactiveTab {
  color: gray;
}

.statContainer {
  display: flex;
  align-items: center;
  gap: 15px;
  font-weight: 600;
}

.statContainer .subTitle {
  font-weight: 500;
  margin-right: 30px;
}

.turnover {
  background-color: #e8f4ff;
  color: #333;
  padding: 5px 10px;
  border-radius: 10px;
}

.icon {
  font-size: 20px;
  cursor: pointer;
}

.summaryContainer {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  font-size: 16px;
}

.totalAmount {
  margin-right: 50px;
  font-weight: bold;
}

.transactionCount {
  font-weight: bold;
}

.download {
  display: flex;
  align-items: center;
  color: gray;
  cursor: pointer;
  font-size: 14px;
}

.settings {
  background: none;
}

.summaryContainer,
.headerContainer {
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  border-radius: 8px 8px 0 0;
}

.summaryContainer,
.headerContainer,
.tableContainer {
  padding-inline: 50px;
}

/* Table */
.tableContainer {
  width: 100%;
  height: 400px;
  margin: 0 auto;
  overflow-y: auto;
  border-radius: 8px;
}

.table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

.tableHeader,
.tableCell {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
  white-space: nowrap;
}

.tableHeader {
  color: lightgray;
}

.tableRow:hover {
  background-color: #f5f5f5;
}

.details {
  font-weight: 500;
  color: #000000;
  cursor: pointer;
}

.tableContainer::-webkit-scrollbar {
  width: 4px;
  height: 2px;
}

.tableContainer::-webkit-scrollbar-track {
  background: #f1f1f1;
  padding: 2px;
}

.tableContainer::-webkit-scrollbar-thumb {
  background: #ff8a35;
  border-radius: 2px;
}

.tableContainer::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media (width<1025px) {
  .root {
    background: #FFFFFF;
  }

  .container {
    margin-block: 0;
  }

  .paginateMenu .addTransaction {
    width: auto;
    font-size: 18px;
  }
  
  .summaryContainer,
  .headerContainer,
  .tableContainer,
  .paginateMenu {
    padding-inline: 16px;
  }

  .paginate {
    display: flex;
    margin: 0 auto;
    width: max-content;
  }

  .tableHeader:first-child,
  .tableCell:first-child {
    padding-left: 0;
  }

  .turnover {
    width: 220px;
    margin: 0 auto;
    padding: 10px;
    font-size: 18px;
  }

  .download {
    text-wrap-mode: nowrap;
  }
}