.Modal {
  position: fixed;
  display: none;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  color: black;
}

.overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); 
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  max-width: 1800px;
  height: 90%;
  overflow: auto;
  padding: 20px;
  border-radius: 12px;
  transition: 0.3s transform;
  background-color: white;
}

.content::-webkit-scrollbar {
  width: 0px;
}

.opened {
  display: block;
  pointer-events: auto;
  opacity: 1;
  z-index: 10;
}
.content_mobile {
  height: 90vh;
}


