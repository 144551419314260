.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-width: 510px;
  margin: 30px auto;
  padding: 30px 10px;
  background-color: #FFF;
  border-radius: 20px;
}

.content {
  width: 100%;
  max-width: 350px;
}

.title {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
  line-height: 23.44px;
}

.setting {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #6D6D6D;
  margin-bottom: 15px;
}

.select_block {
  width: 100%;
  max-width: 215px;
}

.select {
  height: auto;
  margin: 0;
  padding-block: 11px;
  font-family: Roboto;
  font-size: 14px;
}

.edit_button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: 1px solid #000;
  width: 256px;
  padding-block: 15px;
  margin-bottom: 10px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;
}

.edit_button:hover {
  border-radius: 10px;
}

.modal {
  width: 100%;
  max-width: 450px;
  height: max-content;
}

.head {
  display: flex;
  justify-content: space-between;
}

.head .close {
  cursor: pointer;
}

.link_container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #e7e7e7;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  background-color: #ffffff;
  cursor: pointer;
  width: 50%;
}

@media (max-width: 510px) {
  .main {
    overflow-y: scroll;
    padding-bottom: 50px;
  }
  .container {
    margin-inline: 20px ;
  }
}