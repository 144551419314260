.outer_container {
  position: relative;
  display: flex;
  align-items: center;
  width: 1266px;
  height: 557px;
  /* border: 1px solid #000; */
  margin-top: 76px;
}

.inner_container {
  max-width: 799px;
}

.title {
  font-family: Comfortaa;
  font-size: 48px;
  font-weight: 700;
  line-height: 67px;
  letter-spacing: 0em;
}

.sub_title {
  font-family: Comfortaa;
  font-size: 30px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: left;
}

.buttons {
  display: flex;
  column-gap: 20px;
  padding-top: 164px;
}
.buttons button {
  width: 300px;
}

.img {
  position: absolute;
  right: 0px;
  top: 50px;
}

.stores_container {
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  row-gap: 20px;
  column-gap: 40px;
  width: 1266px;
  height: 300px;
  background: #ffe8d7;
  border-radius: 32px;
}

.stores_title {
  font-family: Comfortaa;
  font-size: 32px;
  font-weight: 700;
  color: #000;
}

.stores {
  padding-top: 20px;
  padding-left: 15px;
  display: flex;
  /* border: 1px solid #000; */
}

.planguinimg {
  position: absolute;
  bottom: 20px;
  right: 0px;
}
