.container {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  text-align: center;
  font-size: 18px;
  max-width: 450px;
}

.description {
  text-align: center;
}

.img {
  display: flex;
  justify-content: center;
}
