.popup_background {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.popup_window1 {
  width: 538px;
  height: 600px;
  background: #ffffff;
  border-radius: 20px;
  padding: 35px;
}

.popup_title1 {
  display: flex;
  align-items: center;
  justify-content: center;

  /* border: 1px solid #000; */
  font-weight: 700;
  font-size: 30px;
  line-height: 42px;
  color: #282828;
}

.popup_img1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 320px;
  width: auto;
}
