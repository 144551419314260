.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 1266px;
  height: 768px;
  /* border: 1px solid #000; */
}

.title {
  width: 680px;
  font-size: 36px;
  font-weight: 500;
}

.description {
  padding-top: 50px;

  width: 680px;
  font-size: 24px;
  font-weight: 400;
}

.img {
  position: absolute;
  right: 180px;
}

.background {
  width: 768px;
  height: 768px;
  position: absolute;
  border-radius: 100%;
  left: 190px;
  filter: blur(30px);
  z-index: -1;
  background: linear-gradient(
    180deg,
    rgba(255, 170, 5, 0) -5.58%,
    rgba(255, 169, 3, 0.477083) 134.36%,
    #ffa800 182.99%
  );
}
