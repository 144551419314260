.outer_container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-width: 415px;
  margin: 0 auto;
  color: #000;
}

.form_container {
  width: 100%;
  margin-bottom: 20px;
  padding: 25px 35px;
  border-radius: 20px;
  background-color: #FFF;
}

.form_navigate {
  display: flex;
  z-index: 2;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.header_mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-inline: 35px;
  margin-block: 30px;
}

.header_mobile .back_image svg {
  width: 45px;
  height: 45px;
}

.header_mobile .form_container_title {
  height: max-content;
}

.form_container_title {
  font-size: 20px;
  font-weight: 700;
  line-height: 23.44px;
}

.header_mobile .form_container_title,
.form_navigate .form_container_title {
  display: flex;
  font-size: 24px;
  line-height: normal;
}
.form_navigate .form_container_title img {
  margin-right: 5px;
}

.form_container_text_info {
  margin-block: 15px;
  font-size: 14px;
  font-weight: 200;
  line-height: 16.41px;
}

.link_inner_container {
  display: flex;
}

.link_container { 
  width: 100%;
  margin-right: 8px;
  padding: 10px;
  background-color: #F4F5F7;
  border-radius: 8px;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 256px;
  height: 45px;
  margin-bottom: 10px;
  border-radius: 8px;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;
}

.link:hover,
.link:focus {
  color: #FFFFFF;
  text-decoration: none;
  border: 1px solid #000000;

}

.link_blue {
  background-color: #A0DCFD;
}

.link_purple {
  background-color: #7c59e9;
}

.link_green {
  background-color: #00B61DBA;
}

.link_gray {
  background-color: #979797C2;
}

.link_white {
  background-color: #FFFFFF;
  color: #282828;
  border: 1px solid #000000;
}

.link_white:hover,
.link_white:focus {
  color: #282828;
}