.week_selector_container {
  display: flex;
  width: 350px;
}

.week {
  /* border: 1px solid #000; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 280px;
  padding-left: 10px;
  padding-right: 10px;
}

@media (max-width: 1025px) {
  .week_selector_container {
    width: 190px;
    font-size: 14px;
  }
}
