.popup_window {
  width: 420px;
  max-height: 635px;
  background: #ffffff;
  border-radius: 20px;
  padding: 35px;
  margin-inline: 20px;
}

.titleBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.title {
  font-size: 24px;
  font-weight: 600;
  color: #282828;
}

.toggle_button {
  width: 30px;
  height: 30px;
}

.transaction_id {
  margin-left: 10px;
  color: #FF8A35;
}

.transaction_id.edit {
  color: #282828;
}

.transaction_form {
  color: #6D6D6D
}

.transaction_form .form_container_row {
  display: flex;
  flex-wrap: wrap;
}

.transaction_form .form_container_row .form_row {
  width: 50%;
  min-width: 170px;
}

.transaction_form .form_row {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.form_container_row .form_row:not(:nth-child(1))  .form_row__label {
  text-align: center;
}

.transaction_form .form_row__label {
  width: auto;
  min-width: 63px;
  margin: 10px 0 auto ;
  font-size: 14px;
}

.currency {
  margin-left: 5px;
}

.input,
.select_block,
.select {
  width: 100%;
  height: 40px;
}

.input {
  background: #f4f5f7;
  border-radius: 10px;
  outline: none;
  border: none;
  padding-left: 21px;
}

.select {
  margin: 0;
  padding-block: 11px;
  font-size: 14px;
}

.select option:empty {
  display: none;
}

.submit_block {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 250px;
  margin-bottom: 15px;
}

@media (max-width: 420px) {
.transaction_form .form_container_row .form_row .form_row__label {
    text-align: start;
  }
}