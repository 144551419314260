.container {
  padding-top: 40px;
  display: flex;
  column-gap: 40px;
  padding-bottom: 80px;
}

.title_container {
  display: flex;
  justify-content: flex-start;
}

.plan_container {
  width: 483px;
  /* height: 789px; */
  background: rgba(217, 217, 217, 0.3);
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  row-gap: 15px;
  padding: 50px;
}

.plan_title {
  font-weight: 500;
  font-size: 35px;
  font-family: 'Comfortaa';
  font-style: normal;
}

.options {
  margin-top: 10px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
}
