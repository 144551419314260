.container {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
}

.advantages {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 50px;
}