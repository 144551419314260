label {
  display: block;
  margin-bottom: 10px;
  color: #909090;
  margin-left: 8px;
}
.input,
.textarea {
  font-family: 'Roboto';
  font-size: 16px;
  background: #f4f5f7;
  border-radius: 8px;
  padding: 15px 12px;
  width: 100%;
  border: none;
  font-size: 16px;
  color: #292929;
  outline: none;
}
.textarea {
  height: 138px;
  resize: none;
}
.contact_container {
  display: flex;
  border-bottom: 0.4px solid rgba(208, 208, 208, 0.6);
  justify-content: space-between;
  align-items: center;
  padding-bottom: 25px;
  margin-bottom: 19px;
  height: 55px;
}
.contact_image {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px;
  margin-right: 24px;
  border-radius: 50%;
  background: linear-gradient(180deg, #d2cffa 0%, #bbb6fd 100%);
  font-weight: bold;
  width: 50px;
}

.input_block {
  position: relative;
}

.contactName {
  font-weight: bold;
}

.contact_tel {
  padding-top: 4px;
  font-size: 14px;
}

.right_side {
  display: flex;
}

.channels {
  display: flex;
  column-gap: 12px;
  flex-shrink: 0;
}
