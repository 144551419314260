* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.allClients_container {
  font-family: 'Roboto';
  font-style: normal;
  padding: 32px;
}
.allClients_header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
}
.allClients_title {
  font-weight: 700;
  font-size: 20px;
  line-height: 42px;
  color: #282828;
}
.allClients_button {
  background-color: inherit;
  color: #ff8a35;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.allClients_input {
  padding: 15px 12px 15px 40px;
  margin-bottom: 49px;
}

.allClients_name {
  font-weight: 700;
  margin-bottom: 4px;
}
