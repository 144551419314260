.root {
  position: relative;
}
.dropdown {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 10;
  left: -15px;
}

.dropdown .dropdown_item {
  font-size: 20px;
  background: none;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  text-align: left;
}

.dropdown button:hover {
  background-color: #f8f9fa;
}
  
.switcher {
  background: none;
  margin-right: 20px;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bold;
}