.container_mobile {
  margin-top: 15px;
  min-width: 320px;
  height: 160px;
  background: #fcfffc;
  border-radius: 20px;
  /* border: 1px solid #000; */
  padding: 10px;
  overflow-x: hidden;
  overflow-y: hidden;
}

.line1_mobile {
  /* border: 1px solid #000; */
  display: flex;
  justify-content: center;
}

.line2 {
  display: flex;
  justify-content: center;
  column-gap: 10px;
  padding-top: 15px;
}

.day_container {
  width: 40px;
  height: 60px;
  border-radius: 8px;
  border: 1px solid rgb(201, 201, 201);
}

.day_container:hover {
  width: 40px;

  height: 60px;
  border-radius: 8px;
  border: 1px solid #ff8a35;
}

.day_container_selected {
  width: 40px;

  height: 60px;
  border-radius: 8px;
  border: 1px solid #ff8a35;
  background-color: #ff8a35;
}

.day_container_past {
  width: 40px;

  height: 60px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  background-color: #e0e0e0;
}

.day {
  padding-top: 14px;
  display: flex;
  justify-content: center;
  /* border: 1px solid #000; */
  font-size: 14px;
  color: #333433;
}

.day_selected {
  padding-top: 14px;
  display: flex;
  justify-content: center;
  /* border: 1px solid #000; */
  font-size: 14px;
  color: #ffffff;
}

.day_number {
  display: flex;
  justify-content: center;
  /* border: 1px solid #282828; */
  font-size: 18px;
  font-weight: 600;
}

.day_number_selected {
  display: flex;
  justify-content: center;
  /* border: 1px solid #282828; */
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
}

.week_selector_container {
  display: flex;
  width: 200px;
}

.week {
  /* border: 1px solid #000; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 280px;
  padding-left: 5px;
  padding-right: 5x;
  font-size: 12px;
}
