.link_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 1px solid #000; */
  width: 60px;
}

.link_title {
  font-size: 12px;
  color: #909090;
  padding-top: 5px;
}

.bottom_menu_container {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 60px;
  width: 100%;
  border-top: 1px solid #e7e7e7;
  background-color: rgb(255, 255, 255);
}
