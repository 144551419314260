.popup_background {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.popup_window {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  width: 538px;
  height: 600px;
  background: #ffffff;
  border-radius: 20px;
  padding: 35px;
}

.title {
  position: relative;
  display: flex;
  justify-content: center;
  font-size: 22px;
  font-weight: 600;
}

.service_info_container {
  /* border: 1px solid #000; */
  display: flex;
  column-gap: 12px;
}

.service_info {
  /* border: 1px solid #000; */
}

.service_info_title {
  padding-top: 10px;
  color: #a3a7ae;
}

.service_info_value {
  padding-top: 6px;
  color: #282828;
  font-weight: 600;
}

.service_info2 {
  display: flex;
  column-gap: 40px;
}

.input_title {
  color: #282828;
  padding-bottom: 5px;
}

.service {
  padding-top: 15px;
}

.close {
  position: absolute;
  right: 0px;
}

.service_name {
  /* border: 1px solid #000; */
  font-weight: 500;
  font-size: 20px;
}
