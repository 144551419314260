.form_container {
  /* border: 1px solid #000; */
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  width: 450px;
  padding: 12px;
}

.outer_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.inner_form_container {
  display: flex;
  flex-direction: column;
  row-gap: 19px;
  padding: 40px;
  background: #ffffff;
  border-radius: 20px;
  z-index: 4;
}

.inner_form_container_mobile {
  display: flex;
  flex-direction: column;
  row-gap: 19px;
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 20px;

  background: #ffffff;
  border-radius: 20px;
  z-index: 4;
}

.title {
  /* border: 1px solid #000; */
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #282828;
}

.mobile_form_container {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  padding: 23px;
  margin-top: 40px;
}

.logo_container {
  display: flex;
  justify-content: center;
  align-items: center;
}


.setting {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #6D6D6D;
  margin-bottom: 15px;
}

.setting_name {
  display: block;
  min-width: 45px;
}

.select {
  height: auto;
  margin: 0;
  padding-block: 11px;
  font-family: Roboto;
  font-size: 14px;
}

.select_block {
  width: 100%;
  margin-left: 20px;
}