.container {
  position: relative;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 25px;
  background-color: #282828;
  height: 460px;
  padding: 25px;
  padding-bottom: 75px;
}

.text {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  color: white;
  text-align: center;
}

.mail {
  color: grey;
}

.payment_systems {
  display: flex;
  column-gap: 20px;
}

.social_links {
  position: relative;
  top: -15px;
  /* border: 1px solid #ffffff; */
}

.contact_with_messenger {
  font-weight: 500;
  font-size: 20px;
  line-height: 1.4;
  color: #fefefe;
  text-align: center;
  margin-bottom: 10px;
  max-width: 297px;
}
.messengers {
  display: flex;
  align-items: center;
  gap: 15px;
}
.mail {
  color: white;
  cursor: pointer;
}

.bottom_line {
  display: flex;
  justify-content: center;
  color: white;
  padding-bottom: 2cqh;
  background-color: #282828;
}
