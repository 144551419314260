.notification_container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #ff8a35;
  font-weight: 700;
  font-size: 16px;
  color: white;
  text-align: center;
  row-gap: 15px;
  padding: 20px;
}
