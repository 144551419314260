.timeslot_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 49.41px;
  border-radius: 8px;
  font-size: 15px;
  border: 1px solid #dadada;
  color: #282828;
  font-weight: 500;
}

.container_mobile {
  margin-top: -15px;
  width: 100%;
  background: #ffffff;
  border-radius: 20px;
  /* border: 1px solid #000; */
  padding: 20px;
}

.timeslots_mobile {
  /* border: 1px solid #000; */
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: center;

  column-gap: 15px;
  row-gap: 15px;
  background-color: #ffffff;
}

.emptyblock {
  height: 175px;
}

.img_container {
  /* margin-top: 100px; */
  width: 100%;
  /* border: 1px solid #000; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.nodata_title {
  margin-top: 20px;
  padding-left: 20px;
  font-weight: bold;
  /* border: 1px solid #000; */
}
