.container {
  width: 320px;
  height: 300px;
  background: #ffffff;
  border-radius: 20px;
}

.mobile_container {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 20px;
  row-gap: 10px;
  background: #ffffff;
  margin: 10px;
  border-radius: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 500;
  padding: 15px;
}

.add_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 15px;
  background-color: #f4f5f7;
  color: #ff8a35;
}

.line {
  width: 100%;
  border-bottom: 0.5px solid rgb(225, 225, 225);
}

.appointment_block_container {
  display: flex;
  width: 100%;
  /* height: 55px;  */
  padding-bottom: 10px;
  padding-left: 10px;
  background: #f4f5f7;
  border-radius: 0px 8px 8px 0px;
  flex-shrink: 0;
}

.color {
  width: 4px;
  background-color: #ff8a35;
  height: calc(100% + 10px);
}

.content {
  padding-top: 7px;
  /* border: 1px solid #000; */
  padding-left: 15px;
  width: 100%;
}

.timerange {
  font-size: 15px;
  color: #282828;
}

.serviceName {
  color: #282828;
  font-weight: 600;
}

.appointments_container {
  /* border: 1px solid #000; */
  height: 220px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  row-gap: 15px;
  padding: 20px;
  overflow-y: hidden;
  overflow-y: scroll;
}

/* width */
.appointments_container::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.appointments_container::-webkit-scrollbar-track {
  /* background: #f1f1f1; */
}

/* Handle */
.appointments_container::-webkit-scrollbar-thumb {
  background: #ff8a35;
  border-radius: 2px;
}

/* Handle on hover */
.appointments_container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.popup_background {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  z-index: 10;
}

.popup_window {
  width: 538px;
  height: fit-content;
  background: #ffffff;
  border-radius: 20px;
  margin: 10px;
  padding: 35px;
  z-index: 10;
}

.popup_title {
  position: relative;
  font-size: 21px;
  font-weight: 500;
}

.group_appointment_group__delete {
  float: right;
  margin-right: 43px;
  background: none;
}

.close {
  position: absolute;
  right: 0px;
}

.service_info {
  /* border: 1px solid #000; */

  display: flex;
  column-gap: 50px;
}

.fullname {
  font-size: 21px;
  font-weight: 500;
}

.phone {
  font-size: 21px;
  font-weight: 500;
}

.inner_details_container {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.close_container {
  /* border: 1px solid #000; */
  display: flex;
  align-items: center;
  justify-content: end;
  /* border-bottom: 1px solid rgb(217, 217, 217); */
  /* height: 50px; */
}

.text {
  /* border: 1px solid #000; */
  color: #282828;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
}

.inner_container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.input {
  background: #f4f5f7;
  border-radius: 10px;
  outline: none;
  width: 100%;
  height: 45px;
  border: none;
  padding-left: 21px;
  font-size: 16px;
}

.input_title {
  padding-bottom: 5px;
}

.buttons_container {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 15px;
  width: 100%;
}

.img_container {
  margin-top: 10px;
  /* border: 1px solid #000; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.nodata_title {
  margin-top: 20px;
  padding-left: 10px;
  /* border: 1px solid #000; */
}

.modal_buttons {
  display: flex;
  column-gap: 20px;
  align-items: center;
  /* border: 1px solid #000; */
}

.group_appointment_time {
  font-size: 16px;
  color: #acacac;
}

.group_members {
  /* border: 1px solid #000; */
  height: 400px;
  max-height: 400px;
  overflow: auto;
  overflow-x: hidden;
}

/* width */
.group_members::-webkit-scrollbar {
  width: 3px;
}

/* Track */
/* .services_container::-webkit-scrollbar-track {
    background: #f1f1f1;
  } */

/* Handle */
.group_members::-webkit-scrollbar-thumb {
  background: #ff8a35;
  border-radius: 2px;
}

/* Handle on hover */
.group_members::-webkit-scrollbar-thumb:hover {
  background: #555;
}
