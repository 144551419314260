.popup_background {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.popup_window {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  width: 400px;
  /* height: 325px; */
  background: #ffffff;
  border-radius: 20px;
  padding: 35px;
}

.close_container {
  /* border: 1px solid #000; */
  display: flex;
  align-items: center;
  justify-content: end;
  /* border-bottom: 1px solid rgb(217, 217, 217); */
  /* height: 50px; */
}

.text {
  /* border: 1px solid #000; */
  color: #282828;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
}
