.container {
  width: 982px;
  min-height: 586px;
  background: #ffffff;
  border-radius: 20px;
  padding: 20px 20px 0px 20px;
}

.category_selector_container {
  display: flex;
  column-gap: 20px;
}

.container_header {
  /* border: 1px solid #747474; */
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.selector {
  background-color: transparent;
}

.table_header {
  height: 40px;
  margin-top: 20px;
  display: grid;
  grid-template-columns: 350px 200px;
  align-items: center;
  color: #a3a7ae;
  border-top: 1px solid #a3a7ae;
  border-bottom: 1px solid #a3a7ae;
}

.service_container {
  height: 40px;
  display: grid;
  grid-template-columns: 350px 200px;
  align-items: center;
}

.serviceName {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  /* border: 1px solid #000; */
  padding-right: 10px;
}

@media (width<1024px) {
  .container {
    width: auto;
    min-height: 586px;
    background: #ffffff;
    border-radius: 20px;
    padding: 20px 20px 0px 20px;
  }

  .container_header {
    /* border: 1px solid #747474; */
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 5px;
  }

  .table_header {
    height: 40px;
    margin-top: 20px;
    display: grid;
    grid-template-columns: 210px 30px;
    align-items: center;
    color: #a3a7ae;
    border-top: 1px solid #a3a7ae;
    border-bottom: 1px solid #a3a7ae;
  }

  .service_container {
    height: 40px;
    display: grid;
    grid-template-columns: 210px 30px;
    align-items: center;
  }

  .stats1 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
