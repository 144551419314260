.container {
  /* border: 1px solid #000; */
  width: 870px;
  height: 600px;
  background-color: #ffffff;
  border-radius: 20px;
}

.zaglushka_container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 870px;
  height: 600px;
  background-color: #f4f7fe;
  border-radius: 20px;
  /* border: 1px solid #000;  */
}

.zaglushka_title {
  position: absolute;
  top: 90px;
  left: 60px;
  font-size: 24px;
  width: 400px;
  text-align: center;
}

.inner_container {
  padding: 45px;
}

.block_header {
  /* border: 1px solid #000; */
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: 24px;
  font-weight: 600;
}

.delete {
  cursor: pointer;
}

.line1 {
  /* border: 1px solid #000; */
  display: flex;
  /* justify-content: space-between; */
  column-gap: 28px;
}

.name_label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #6d6d6d;
  font-size: 14px;
  width: 60px;
  /* border: 1px solid #000; */
  flex-shrink: 0;
}

.inputs_container {
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  row-gap: 20px;
}

.price {
  /* border: 1px solid #000; */
}

.duration {
  /* border: 1px solid #000; */
}

.line2 {
  /* border: 1px solid #000; */
  display: flex;
  column-gap: 45px;
}

.price {
  display: flex;
  column-gap: 31px;
}

.duration {
  width: 100%;
  display: flex;
  column-gap: 25px;
}

.price_label {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #6d6d6d;
  font-size: 14px;
}

.duration_label {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #6d6d6d;
  font-size: 14px;
}

.line3 {
  display: flex;
  column-gap: 53px;
}

.description_label {
  color: #6d6d6d;
  font-size: 14px;
}

.button_container {
  /* border: 1px solid #000; */
  margin-top: 30px;
  display: flex;
  justify-content: end;
}

.group_time_slot_container {
  display: flex;
  align-items: center;
  column-gap: 10px;
  color: #6d6d6d;
  font-size: 14px;
}

.group_time_slots_container {
  /* border: 1px solid #000; */
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.sections {
  display: flex;
  column-gap: 20px;
  cursor: pointer;
  color: grey;
  user-select: none;
}

.client_container {
  display: flex;
  border-bottom: 0.4px solid #cecece;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.client_container:last-of-type {
  border: none;
}

.client_block {
  display: flex;
  padding: 28px 0;
  align-items: center;
  max-width: 650px;
  height: 50px;
  width: 650px;
}
.client_image {
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px;
  margin-right: 24px;
  border-radius: 50%;
  background: linear-gradient(180deg, #d2cffa 0%, #bbb6fd 100%);
  font-size: 10px;
}
.client_name {
  margin-right: 24px;
}

.client_name:hover {
  color: #ff8a35;
}

.client_tel {
  margin-left: auto;
  margin-right: 0;
  padding-right: 20px;
}

@media (max-width: 1025px) {
  .client_tel {
    font-size: 13px;
    color: 'grey';
  }
}

.search_client_container {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
