.container {
  /* border: 1px solid #000; */
  width: 986px;
  min-height: 400px;
  background: #ffffff;
  border-radius: 20px;
  padding: 20px;
}

.inner_container {
  /* border: 1px solid rgb(194, 120, 9); */
  display: flex;
  width: 100%;
  height: 100%;
}

.left_side {
  /* border: 1px solid #000; */
  height: 100%;
  width: 300px;
  border-right: 1px solid rgb(225, 225, 225);
}

.right_side {
  display: flex;
  flex-direction: column;
  /* border: 1px solid rgb(168, 23, 23); */
  height: 100%;
  width: 100%;
  padding-left: 18px;
  padding-right: 18px;
  row-gap: 15px;
}

.photo_container {
  padding-top: 50px;
  /* border: 1px solid #000; */
  display: flex;
  justify-content: center;
}

.socials_container {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  /* border: 1px solid rgb(157, 7, 7); */
  padding: 20px;
}

.edit_socials {
  font-weight: 600;
  font-size: 14px;
}

.button_container {
  /* border: 1px solid #000; */
  display: flex;
  justify-content: flex-end;
}

.edit_button_container {
  /* border: 1px solid #000; */
  display: flex;
  justify-content: end;
  height: 30px;
}

.mobile_profile_container {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 25px;
  border-radius: 20px;
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
}

.mobile_links_container {
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.mobile_profile_data_container {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  row-gap: 22px;
}

.fullname {
  display: flex;
  column-gap: 10px;
  padding-left: 10px;
}

.company_hint {
  position: relative;
  top: -14px;
  /* border: 1px solid #000; */
  font-size: 12px;
  color: #bababa;
}
