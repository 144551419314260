.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background-color: #ff8a35;
  color: white;
  column-gap: 25px;
}

.button {
  background-color: aliceblue;
  width: 100px;
  height: 30px;
  border-radius: 5px;
  outline: none;
  border: none;
  color: #ff8a35;
}

.button:hover {
  background-color: rgb(249, 252, 255);
  width: 103px;
  height: 33px;
  font-weight: bold;
}

@media (width<1024px) {
  .container {
    min-height: 65px;
    background-color: #ff8a35;
    color: white;
    column-gap: 25px;
    padding: 15px;
    font-size: 15px;
  }

  .button {
    background-color: aliceblue;
    width: 120px;
    height: 30px;
    border-radius: 5px;
    outline: none;
    border: none;
    color: #ff8a35;
    font-weight: bold;
  }
}
