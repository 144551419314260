.container {
  position: relative;
  width: 100vw;
  height: 100%;
  background: #fffefe;
  /* border-radius: 20px; */
  /* border: 1px solid #000; */
  background-color: #ffff;
  padding: 15px;
}

.call_button {
  display: flex;
  justify-content: end;
  /* border: 1px solid #000; */
  padding-top: 20px;
  padding-right: 40px;
}

.avatar {
  /* border: 1px solid #000; */
  display: flex;
  justify-content: center;
}

.name {
  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.about {
  /* border: 1px solid #000; */
  display: flex;
  justify-content: center;
  padding-top: 15px;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
}

.hint {
  display: flex;
  justify-content: center;
  padding-top: 30px;
  font-weight: 500;
  /* display: none; */
}

.button_container {
  padding-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.social_links {
  display: flex;
  justify-content: center;
  column-gap: 20px;
  padding-top: 15px;
}

.services_title {
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 21px;
  height: 50px;
  padding-top: 15px;
  padding-bottom: 20px;

  /* border: 1px solid #000; */
}

.services_container {
  padding-top: 20px;
  display: flex;
  row-gap: 15px;
  flex-direction: column;
}

.buttons_container {
  /* border: 1px solid #000; */
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
  background-color: #fffefe;
  padding: 15px;
}

.confirmation_container {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  position: relative;
  width: 100%;
  height: 100vh;
  background: #fffefe;
  /* border: 1px solid #000; */
  background-color: #ffff;
  padding: 15px;
}

.service_info2 {
  /* border: 1px solid #000; */
  display: flex;
  column-gap: 5px;
}

.confirmation_title {
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
}

.input_title {
  padding-bottom: 5px;
}

.success_container {
  width: 100vw;
  height: 100vh;
  background: #ffffff;
  padding: 35px;
}

.title {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-weight: 600;
  padding-bottom: 20px;
}

.img_title {
  display: flex;
  justify-content: center;
  font-size: 22px;
  font-weight: 300;
  padding-bottom: 30px;
}

.img {
  margin-top: -20px;
}

.close {
  position: absolute;
  right: 0px;
}

.avatar_container {
  /* border: 1px solid #000; */
  display: flex;
  justify-content: center;
}

.inner_container {
  /* border: 1px solid #000; */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
.avatar {
  width: 250px;
}

.empty_block {
  height: 150px;
}
.service_value {
  font-weight: bold;
  font-size: 18px;
}

.img_container {
  /* margin-top: 100px; */
  width: 100%;
  /* border: 1px solid #000; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.nodata_title {
  margin-top: 20px;
  padding-left: 10px;
  font-weight: bold;
  /* border: 1px solid #000; */
}

.service_description {
  /* border: 1px solid #000; */
  border: none;
  outline: none;
  height: 700px;
  /* overflow-y:auto; */
  font-family: Roboto;
  font-size: 16px;
  resize: none;
}

.property {
  font-weight: bold;
}

.category {
  /* border: 1px solid #000; */
  background-color: #d9d9d966;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  width: fit-content;
  height: 30px;
  white-space: nowrap;
  padding-left: 15px;
  padding-right: 15px;
}

.categories_container {
  margin-top: 15px;
  /* border: 1px solid #000; */
  display: flex;
  column-gap: 15px;
  overflow-x: scroll;
  margin-bottom: 15px;
  overflow-y: hidden;
}

.categories_container {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.categories_container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
