.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  background: #ebf1ff;
  border-radius: 32px;
  padding: 30px;
  height: 500px;
}

.img {
  display: flex;
  justify-content: center;
}

.title {
  font-family: Comfortaa;
  margin-top: 25px;
  font-size: 16px;
  font-weight: 700;
  color: #000;
  text-align: center;
}

.subtitle {
  font-family: Comfortaa;

  margin-top: 20px;
  font-size: 14px;
  font-weight: 500;
  color: #000;
  text-align: center;
}

.inputs {
  /* border: 1px solid #000; */
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}
