.transactions_container {
  width: 827px;
  min-height: 339px;
  background: #ffffff;
  border-radius: 20px;
  padding: 30px;
}

.transactions_title {
  /* border: 1px solid #000; */
  font-weight: 700;
  font-size: 18px;
}

.transactions_data {
  margin-top: 15px;
  /* border: 1px solid #000; */
  display: grid;
  grid-template-columns: 260px 260px 260px;
  border-bottom: 1px solid #e1e1e1;
  font-weight: 300;
  font-size: 14px;
  padding-bottom: 7px;
}

.transactions_row {
  margin-top: 10px;
  /* border: 1px solid #000; */
  display: grid;
  grid-template-columns: 260px 260px 260px;
  /* border-bottom: 1px solid grey; */
  font-weight: 300;
  font-size: 14px;
}