.container1 {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* border: 1px solid #000; */
  height: 60px;
  background-color: #ff9999;
  color: white;
  z-index: 7;
}

.container2 {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* border: 1px solid #000; */
  height: 60px;
  background-color: #3fd28c;
  color: white;
  z-index: 7;
}
