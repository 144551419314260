.container {
  /* display: flex;
flex-direction: column; */

  display: grid;
  grid-template-columns: 160px 160px;
  grid-template-rows: 60px 60px 60px 60px;
  justify-content: center;
  column-gap: 10px;
  padding: 25px;
  row-gap: 5px;
  background-color: #f4f7fe;
}

.link_container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #e7e7e7;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  background-color: #ffffff;
}

.link_container.disabled {
  opacity: 0.5;
  background: #e7e7e7;
}

.languageContainer {
  position: relative;
}

.inDevelopment {
  position: absolute;
  top: 2px;
  right: 5px;
  padding: 0px 3px;
  border-radius: 2px;
  background: #FF8A35;
  color: #FFFFFF;
  font-size: 10px;
  text-wrap: nowrap;
}