.popup_background {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  z-index: 1;
}

.popup_window {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  width: 400px;
  height: 600px;
  background: #ffffff;
  border-radius: 20px;
  padding: 35px;
}

.mobile_container {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  background: #ffffff;
  padding: 20px;
}

.title_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttons_container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 15px;
}

.title {
  font-size: 18px;
  font-weight: bold;
}
