.function_container {
  width: 150px;
  height: 175px;
  border: 1px solid #989a99;
  border-radius: 32px;
  font-weight: 500;
  font-size: 20px;
  color: #989a99;
  padding: 10px;
}

.function_line2 {
  padding-top: 10px;
  font-size: 14.5px;
}

.step_container {
  display: flex;
  width: 325px;
  height: 100px;
  background: #f6f6f6;
  border-radius: 32px;
}

.step_number {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
  color: #ff6e05;
  padding-left: 25px;
}

.step_title {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  padding-left: 30px;
  padding-right: 15px;
}

.for_whom_container {
  width: 340px;
  height: 180px;
  border: 1px solid #000000;
  border-radius: 32px;
  padding: 32px;
}

.for_whom_line1 {
  font-weight: 500;
  font-size: 24px;
}

.for_whom_line2 {
  padding-top: 15px;
}

.input {
  font-family: M;
  font-size: 20px;
  color: white;
  border: 1px solid #ffffff;
  border-radius: 32px;
  width: 268px;
  height: 40px;
  background-color: transparent;
  padding-left: 20px;
  color: #ffffff;
  outline: none;

  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
}

.input::placeholder {
  color: #ffffff;

  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}
