.container {
  width: 888px;
  height: 183px;
  background: #fcfffc;
  border-radius: 20px;
  /* border: 1px solid #000; */
  padding: 20px;
}
.container_mobile {
  margin-top: 15px;
  width: 100vw;
  height: 183px;
  background: #fcfffc;
  border-radius: 20px;
  /* border: 1px solid #000; */
  padding: 20px;
  overflow-x: scroll;
  overflow-y: hidden;
}

/* width */
.container_mobile::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.container_mobile::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.container_mobile::-webkit-scrollbar-thumb {
  background: #ff8a35;
  border-radius: 2px;
}

/* Handle on hover */
.container_mobile::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.line1 {
  /* border: 1px solid #000; */
  display: flex;
  justify-content: space-between;
}

.line1_mobile {
  /* border: 1px solid #000; */
  display: flex;
  justify-content: center;
}

.title {
  display: flex;
  align-items: center;
  font-size: 21px;
  font-weight: 500;
}

.line2 {
  display: flex;
  column-gap: 15px;
  padding-top: 15px;
}

.day_container {
  width: 106px;
  height: 74px;
  border-radius: 8px;
  border: 1px solid rgb(201, 201, 201);
}

.day_container:hover {
  width: 106px;
  height: 74px;
  border-radius: 8px;
  border: 1px solid #ff8a35;
}

.day_container_selected {
  width: 106px;
  height: 74px;
  border-radius: 8px;
  border: 1px solid #ff8a35;
  background-color: #ff8a35;
}

.day_container_past {
  width: 106px;
  height: 74px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  background-color: #e0e0e0;
}

.day {
  padding-top: 14px;
  display: flex;
  justify-content: center;
  /* border: 1px solid #000; */
  font-size: 14px;
  color: #333433;
}

.day_selected {
  padding-top: 14px;
  display: flex;
  justify-content: center;
  /* border: 1px solid #000; */
  font-size: 14px;
  color: #ffffff;
}

.day_number {
  display: flex;
  justify-content: center;
  /* border: 1px solid #282828; */
  font-size: 22px;
  font-weight: 600;
}

.day_number_selected {
  display: flex;
  justify-content: center;
  /* border: 1px solid #282828; */
  font-size: 22px;
  font-weight: 600;
  color: #ffffff;
}

.week_selector_container {
  display: flex;
  width: 350px;
}

.week {
  /* border: 1px solid #000; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 280px;
  padding-left: 10px;
  padding-right: 10px;
}
