.main_container {
  position: relative;
  height: 100vh;
  width: 100vw;

  display: flex;
  align-items: center;
  justify-content: center;
}

.content_container {
  position: relative;
  top: -60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 1px solid #3e3e3e; */
  text-align: center;
  padding: 15px;
}

.title {
  font-size: 20px;
  font-weight: bold;
}

.subtitle {
  margin-top: 10px;
  text-align: center;
  color: #ff8a35;
}

.buttons_container {
  position: fixed;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 15px;

  bottom: 0px;
  left: 0px;
  right: 0px;
}
