.plan_block_container {
  width: 835px;
  height: 104px;
  background: rgba(250, 191, 125, 0.4);
  border-radius: 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
}

.plan {
  font-size: 24px;
  font-weight: 700;
}
