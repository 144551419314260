.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 320px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.header_title {
  margin-left: 30px;
  font-size: 2rem;
  font-weight: bold;
  color: #000;
}

#image_cropper {
  width: auto;
  height: 0;
  opacity: 0;
  overflow: hidden;
}
.imageCropper_image__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  margin-bottom: 30px;
}
.imageCropper__title {
  text-align: center;
  background-color: #979797;
  color: white;
  padding: 11px;
  font-size: 1.5rem;
  border-radius: 10px;
  margin-top: 1rem;
}

.preview {
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  margin-block: 10px;
}

.imageCrop_canvas {
  display: none;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-top: 20px;
}

.imageCrop_canvas.show {
  display: block;
}
