.page_title {
  font-size: 26px;
  font-weight: bold;
  color: grey;
}

.main_container {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  padding: 5px;
}


.back_button_container {
}

.options {
  margin-top: 10px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}

.options_container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  /* width: 408px; */
  min-height: 500px;
  background-color: white;
  border-radius: 20px;
  padding: 25px;
}

.options_title {
  display: flex;
  align-items: center;
  column-gap: 25px;

  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
}

.options_block {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 300px;
  background: #f4f7fe;
  border-radius: 20px;
  padding: 20px;
}

.options_block_title {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 700;
}

.option {
  /* border: 1px solid #000; */
  display: flex;
  align-items: center;
  column-gap: 10px;

  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  color: #505050;
}

.discount {
  color: #ff8a35;
  font-weight: 500;
}

.row {
  /* border: 1px solid #000; */
  display: flex;
  justify-content: space-between;
  color: #505050;
  font-size: 15px;
}

.conditions {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  color: #979797;
}

.options_outer_container {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}
