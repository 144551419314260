.container {
  margin-top: 20px;
  /* border: 1px solid #000; */
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  column-gap: 20px;
}

.left_side {
  display: grid;
  row-gap: 20px;
}

.time_left {
  font-size: 14px;
}

.button {
  background-color: white;
  color: #ff8a35;
}

.options {
  margin-top: 10px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}

.back_button_container {
  padding-top: 20px;
  display: flex;
  justify-content: center;
  /* border: 1px solid #000; */
}

.options_container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 408px;
  min-height: 500px;
  background-color: white;
  border-radius: 20px;
  padding: 25px;
}

.options_title {
  display: flex;
  align-items: center;
  column-gap: 25px;

  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
}

.options_block {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 347px;
  background: #f4f7fe;
  border-radius: 20px;
  padding: 20px;
}

.options_block_title {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 700;
}

.option {
  /* border: 1px solid #000; */
  display: flex;
  align-items: center;
  column-gap: 10px;

  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  color: #505050;
}

.discount {
  color: #ff8a35;
  font-weight: 500;
}

.row {
  /* border: 1px solid #000; */
  display: flex;
  justify-content: space-between;
  color: #505050;
  font-size: 15px;
}

.conditions {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  color: #979797;
}

.options_outer_container {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.popup_window {
  width: 320px;
  height: min-content !important;
}

.popup_window .close_container {
  display: flex;
  align-items: center;
  justify-content: end;
  width: max-content;
  margin-left: auto;
}

.popup_window .textInfo {
  margin-top: 10px;
  color: #282828;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
}

.popup_window .modal_buttons {
  display: flex;
  column-gap: 20px;
  align-items: center;
}