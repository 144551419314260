.channel_container {
  width: 415px;
  min-height: 196px;
  background: #ffffff;
  border-radius: 20px;
  padding: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 15px;
}

.channel_title {
  text-align: center;
  font-weight: 700;
  font-size: 24px;
}

.cahnnel_subtitle {
  text-align: center;
  font-weight: 300;
  font-size: 14px;
}

.outer_container {
  margin-top: 22px;
  display: flex;
  justify-content: center;
}

.container {
  /* border: 1px solid #000; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: 22px;
}

.line1 {
  display: flex;
  column-gap: 45px;
}

.line2 {
  display: flex;
  column-gap: 45px;
}
.channel_button_container {
  display: flex;
  justify-content: center;
}
.line_title {
  font-weight: 700;
  font-size: 24px;
}

.popup_background {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  z-index: 6;
}

.popup_window {
  width: 438px;
  height: 450px;
  background: #ffffff;
  border-radius: 20px;
  padding: 35px;
  z-index: 1;
}

.title_container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
}

.img_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.description {
  text-align: center;
}

.step_container {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.subtitle {
  font-weight: bold;
}

.link {
  color: #ff8a35;
  font-weight: bold;
}

.support {
  margin-top: 10px;
  display: flex;
  justify-content: center;

  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  color: #979797;
}

@media (max-width: 425px) {
  .popup_window {
    width: 320px;
    height: 470px;
  }

  .title_container {
    font-size: 16px;
    text-align: center;
  }
}
