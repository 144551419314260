.inner_container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 1266px;
  height: 768px;
}

.line1 {
  padding-top: 40px;
  display: flex;
  justify-content: space-between;
}

.line2 {
  padding-top: 40px;
  display: flex;
  justify-content: space-between;
  height: 300px;
}

.img {
  position: absolute;
  left: calc(50% - 255px);
  bottom: -20px;
}
