.container {
  /* border: 1px solid rgb(168, 9, 9); */
  display: flex;
  justify-content: center;
}

.left_side {
  /* border: 1px solid #000; */
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 10px;
}

.right_side {
  padding-top: 10px;
}

.calendar {
  display: flex;
  justify-content: center;
  width: 320px;
  height: 300px;
  background: #ffffff;
  border-radius: 20px;
  overflow: hidden;
}

.shedule {
  /* width: 950px;
    height: 600px;
    background: #FFFFFF;
    border-radius: 20px; */
}
