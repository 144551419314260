.label {
  position: relative;
  display: block;
  margin-bottom: 10px;
  color: #6d6d6d;
  font-size: 12px;
  line-height: 14px;
  color: #6d6d6d;
}
.input {
  font-family: 'Roboto';
  font-size: 20px;
  background: #f4f5f7;
  border-radius: 8px;
  padding: 15px 15px;
  height: 44px;
  width: 100%;
  border: none;
  font-size: 16px;
  outline: none;
  margin-bottom: 15px;
}

.textarea {
  background: #f4f5f7;
  border-radius: 8px;
  padding: 15px 12px;
  height: 138px;
  resize: none;
  outline: none;
  font-family: 'Roboto';
  font-size: 16px;
  border: none;
  width: 100%;
}

.client_container {
  display: flex;
  border-top: 0.4px solid #cecece;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.client_block {
  display: flex;
  padding: 28px 0;
  align-items: center;
  max-width: 650px;
  height: 75px;
  width: 650px;
}
.client_image {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px;
  margin-right: 24px;
  border-radius: 50%;
  background: linear-gradient(180deg, #d2cffa 0%, #bbb6fd 100%);
}
.client_name {
  margin-right: 24px;
}

.client_name:hover {
  color: #ff8a35;
}

.client_tel {
  margin-left: auto;
  margin-right: 0;
}

.button {
  height: 55px;
  width: 100%;
  background: #ff8a35;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 700;
  border: none;
  color: #ffff;
  cursor: pointer;
}

.select {
  position: relative;
    font-size: 16px;
    line-height: 19px;
    width: 100%;
    height: 44px;
    border: none;
    background: #f4f5f7;
    border-radius: 8px;
    color: #565656;
    padding: 15px;
    padding-right: 20px;
    margin-bottom: 15px;
    outline: none;
    cursor: pointer;
    appearance: none;
    background-image: url('data:image/svg+xml;utf8,<svg fill="%23565656" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>');
    background-repeat: no-repeat;
    background-position: right 5px center;
    background-size: 22px;
}
.selectLabel {
  position: relative;
}
.selectLabel::after {
  content: '';
  /* background: url(../AddContactForm/Images/selectImage.svg) no-repeat; */
  background-size: 12px 12px;
  width: 12px;
  height: 12px;
  position: absolute;
  right: 13px;
  top: 43px;
  pointer-events: none;
  z-index: 5;
}

.channels {
  /* border: 1px solid #000; */
  display: flex;
  column-gap: 20px;
  align-items: center;
}
