.container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh+100px);
  background-color: #ffff;
  padding: 20px;
  /* border: 1px solid #000; */
}

.services_container {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-top: 20px;
}

.button_container {
  position: fixed;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background-color: #ffff;
}

.title {
  position: relative;
  font-size: 20px;
  font-weight: 500;
  /* border: 1px solid #000; */
}

.inputs_container {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-top: 20px;
}

.duration_container {
  display: flex;
  column-gap: 20px;
}

.price_container {
  display: flex;
  column-gap: 20px;
}

.price_label {
  display: flex;
  align-items: center;
  /* border: 1px solid #000; */
  width: 340px;
}

.duration_label {
  display: flex;
  align-items: center;
  /* border: 1px solid #000; */
  width: 200px;
}

.delete {
  position: absolute;
  right: 0px;
}

.emty_block {
  height: 150px;
}

.img_container {
  margin-top: 80px;
  /* border: 1px solid #000; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.nodata_title {
  margin-top: 20px;
  padding-left: 20px;
  /* border: 1px solid #000; */
}

.duration {
  display: flex;
  column-gap: 15px;
}

.title_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
