.container {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 40px;
}

.title_container {
  display: flex;
  justify-content: flex-start;
}

.plan_container {
  width: 340px;
  /* height: 620px; */
  background: rgba(217, 217, 217, 0.3);
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  row-gap: 15px;
  padding: 30px;
  /* border: 1px solid #000; */
}

.plan_title {
  font-weight: 500;
  font-size: 25px;
  font-family: 'Comfortaa';
  font-style: normal;
}

.options {
  margin-top: 10px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}
