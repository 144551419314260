.container {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
}

.forWhom {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  align-items: center;
}
