.container {
  width: 470px;
  height: 600px;
  /* border: 1px solid #000; */
  background-color: #ffffff;
  border-radius: 20px;
}

.inner_container {
  padding: 40px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.services_container {
  /* border: 1px solid #000; */
  height: 370px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 10px;
}

/* width */
.services_container::-webkit-scrollbar {
  width: 3px;
}

/* Track */
/* .services_container::-webkit-scrollbar-track {
    background: #f1f1f1;
  } */

/* Handle */
.services_container::-webkit-scrollbar-thumb {
  background: #ff8a35;
  border-radius: 2px;
}

/* Handle on hover */
.services_container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.img_container {
  margin-top: 100px;
  /* border: 1px solid #000; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.nodata_title {
  margin-top: 20px;
  padding-left: 20px;
  /* border: 1px solid #000; */
}

.category_container {
  display: flex;
  align-items: center;
  column-gap: 12px;
}

.category_name {
  font-size: 18px;
  font-weight: bold;
  width: 100%;
  height: 60px;
  /* border: 1px solid #000; */
  /* border-bottom:1px solid grey ; */
  display: flex;
  align-items: center;
}

.service_type {
  /* border: 1px solid #000; */
}
