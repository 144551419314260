.container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 10px;
}

.title {
  /* text-align: center; */
  font-size: 32px;
  font-weight: 700;

  font-family: Comfortaa;
}

.subtitle {
  /* text-align: center; */
  font-weight: 700;
  font-size: 18px;
  font-family: Comfortaa;
}

.description {
  /* text-align: center; */
  padding-top: 10px;
  font-style: 14px;
  /* border: 1px solid #000; */
  padding-bottom: 15px;
}

.img_container {
  position: relative;
  display: flex;
  justify-content: center;
}

.img {
  margin: 31px 0 34px;
  width: 100%;
}

.stores_container {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 20px;
  height: 300px;
  background: #ffe8d7;
  border-radius: 32px;
}

.stores_title {
  text-align: center;
  font-family: Comfortaa;
  font-size: 20px;
  font-weight: 700;
  color: #000;
}

.stores {
  padding-top: 10px;
  /* border: 1px solid #000; */
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 10px;
  justify-content: center;
}

.circle {
  position: absolute;
  right: 0px;
  bottom: 50px;
  width: 255px;
  height: 255px;
  border-radius: 100%;
  background-color: #fda829;
  filter: blur(59.5px);
  z-index: -1;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
