.mobile_header_container {
  display: flex;
  background-color: #f4f7fe;
  height: 60px;
  justify-content: space-between;
  width: 100%;
}

.logo {
  padding-left: 12px;
  /* border: 1px solid #000; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu {
  padding-right: 18px;
  /* border: 1px solid #000; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.desktop_header_container {
  position: relative;

  display: flex;
  justify-content: space-between;
  /* border: 1px solid #000; */
  width: 100%;
  height: 80px;
  border: 0.5px solid #ebebf9;
  box-shadow: 8px 8px 16px 4px rgba(133, 139, 146, 0.06);
}

.logo {
  /* border: 1px solid #000; */
}

.links {
  display: flex;
  align-items: center;
  column-gap: 85px;
  /* border: 1px solid #000; */
  font-weight: 400;
  font-size: 18px;
  cursor: pointer;
}

.account {
  display: flex;
  align-items: center;
  /* border: 1px solid #000; */
  column-gap: 14px;
  padding-right: 15px;
}

.photo {
  display: flex;
  justify-content: center;
  align-items: center;

  /* background-color: aquamarine; */
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
}

.specialist {
  cursor: pointer;
  user-select: none;
}

.link_container {
  position: relative;
}

.label {
  position: absolute;
  right: -14px;
  top: -8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 11.83px;
  background: #ff8a35;
  border-radius: 2px;
  font-size: 10px;
  color: #ffff;
}

.desktop_menu {
  position: absolute;
  top: 65px;
  right: 15px;
  width: 215px;
  /* height: 263px; */
  background: #ffffff;
  border: 1px solid #9f9f9f;
  border-radius: 20px;
  z-index: 10;
  color: #282828;
  font-size: 16px;
}

.mobile_menu {
  position: absolute;
  top: 65px;
  right: 15px;
  width: 280px;
  /* height: 263px; */
  background: #ffffff;
  border: 1px solid #9f9f9f;
  border-radius: 20px;
  z-index: 3;
}

.menu_item {
  /* border: 1px solid #000; */
  display: flex;
  align-items: center;
  column-gap: 15px;
  height: 45px;
  padding-left: 34px;
}

.menu_item:hover {
  /* border: 1px solid #000; */
  display: flex;
  align-items: center;
  column-gap: 15px;
  height: 45px;
  padding-left: 34px;
  color: #ff8a35;
  cursor: pointer;
}

.menu_item_icon {
  width: auto;
}

.disabled {
  opacity: 0.5;
  cursor: default;
}

.disabled:hover {
  color: initial;
}

.mobile_menu_buttons_container {
  padding: 15px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.client_header_container {
  display: flex;
  justify-content: space-between;
  /* border: 1px solid #000; */
  width: 100vw;
  height: 60px;
  padding-left: 40px;
  padding-right: 40px;
}

.client_header_logo {
  display: flex;
  align-items: center;
  /* border: 1px solid #000; */
}

.notification {
  display: flex;
  align-items: center;
  /* border: 1px solid #000; */
}

.link {
  font-size: 20px;
  font-weight: 500;
}

.links_container {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  padding: 30px;
}

.mobileHeaderContainer {
  /* border: 1px solid #000; */
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
}

.mobileHeaderContainer2 {
  /* border: 1px solid #000; */
  height: 60px;
  display: grid;
  grid-template-columns: 50px auto 50px;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.header_title {
  font-size: 20px;
  font-weight: bold;
  color: grey;
}

.account_container {
  display: flex;
  align-items: center;
}

.inDevelopment {
  position: absolute;
  top: -5px;
  left: 35px;
  padding: 0px 3px;
  border-radius: 2px;
  background: #FF8A35;
  color: #FFFFFF;
  font-size: 8px;
  text-wrap: nowrap;
}