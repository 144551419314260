
.sms_topup_container {
  background-color: #ffffff !important;

  width: 408px;
  height: 462px;
  border-radius: 20px;
  /* border: 1px solid #000; */

  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 40px;
}

.sms_topup_line1 {
  /* border: 1px solid #000; */
  display: flex;
  column-gap: 15px;
}

.sms_topup_img_container {
  /* border: 1px solid #000; */
}

.sms_topup_title {
  font-weight: 700;
  font-size: 24px;
}

.sms_topup_subtitle {
  font-weight: 300;
  font-size: 14px;
}

.sms_topup_status {
  font-weight: 300;
  font-size: 14px;
  color: coral;
}

.sms_topup_balance {
  font-weight: 700;
  font-size: 16px;
}

.sms_topup_topup {
  /* border: 1px solid #000; */
  display: flex;
  align-items: center;
}

.sms_topup_icn,
.sms_topup_dec {
  min-width: 25px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #D9D9D9;
  color: #fff;
}

.sms_topup_icn {
  margin-right: 8px;
}

.sms_topup_topup_right_side_title {
  width: 179px;
  font-weight: 700;
  font-size: 16px;
}

.sms_topup_topup_right_side_subtitle {
  font-weight: 300;
  font-size: 12px;
}

.sms_topup_info {
  display: grid;
  grid-template-columns: auto auto;

  font-weight: 300;
  font-size: 12px;
  row-gap: 5px;
}

.sms_topup_info_cell_bold {
  font-weight: 700;
  font-size: 16px;
}
