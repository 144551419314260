.inputClass {
  font-size: 16px !important;
  background: #f4f5f7 !important;
  border: none !important;
  width: 100% !important;
  height: 100% !important;
  min-height: 44px;
  border-radius: 8px !important;
  color: #565656;
}

.buttonClass,
.buttonClass div[class='selected-flag open'],
.buttonClass div[class='selected-flag']:hover,
.buttonClass div[class='selected-flag']:focus {
  border: none !important;
  background: #f4f5f7 !important;
}

.buttonClass div[class='selected-flag'] {
  border-radius: 10px !important;
}

.label {
  position: relative;
  display: block;
  margin-bottom: 10px;
  color: #6d6d6d;
  font-size: 12px;
  line-height: 14px;
  color: #6d6d6d;
}