.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 40px;
  width: 1266px;
  height: 768px;
  /* border: 1px solid #000; */
}
.line1 {
  display: flex;
  column-gap: 40px;
}

.line2 {
  display: flex;
  column-gap: 40px;
}

.title {
  /* border: 1px solid #000; */
  width: 1221px;
}
