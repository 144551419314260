.container {
  /* border: 1px solid #000; */
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.inner_container {
  display: flex;
  padding-top: 22px;
  column-gap: 22px;
}

.emty_edit_block {
  width: 870px;
  height: 600px;
  background-color: #ffffff;
  border-radius: 10px;
}

.img_container {
  margin-top: 140px;
  /* border: 1px solid #000; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.nodata_title {
  margin-top: 20px;
  padding-left: 20px;
  /* border: 1px solid #000; */
}
