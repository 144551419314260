.plan_block_container {
  background: rgba(250, 191, 125, 0.4);
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  row-gap: 15px;
}

.plan {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 700;
}