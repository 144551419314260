.weekChanger_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 53px;
  width: 100%;
  border-radius: 8px;
  background-color: #ffffff;

  padding-left: 22.5px;
  padding-right: 22.5px;
}

@media (width<1025px) {
  .weekChanger_container {
    position: fixed;
    width: calc(100% - 40px);
  }
  .month {
    font-size: 14px;
  }
}
