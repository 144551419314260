.container {
  margin-top: 100px;
  background: #ebf1ff;
  border-radius: 32px;
  padding-bottom: 30px;
}

.item {
  width: 300px;
  display: flex;
  column-gap: 15px;
  align-items: center;
  /* border: 1px solid #000; */
}

.circle {
  /* border: 1px solid #000; */
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #000;
}

.title {
  /* border: 1px solid #000; */
  color: #000;
  font-size: 16px;
  font-weight: 500;
  width: 280px;
}

.img_container {
  position: relative;
  display: flex;
  justify-content: center;
  /* border: 3px solid rgb(165, 35, 35); */
  height: 180px;
  overflow: visible;
}

.img {
  position: relative;
  top: -120px;
  left: -20px;
}

.functions {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  /* border: 1px solid #000; */
  bottom: 70px;
  left: 40px;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}
