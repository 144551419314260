.container {
  display: flex;
  flex-direction: column;

  width: 895px;
  height: max-content;
  margin-bottom: 20px;
  background: #ffffff;
  box-shadow: 8px 8px 16px 4px rgba(137, 143, 150, 0.1);
  border-radius: 20px;
}

.fragment {
  display: flex;
}

.block {
  min-width: 220px;
  /* border: 1px solid #000; */
  padding: 20px;
  color: #a3a7ae;
  border-right: 1px solid #ececee;
}

.value {
  padding-top: 6px;
  color: #282828;
  font-weight: 600;
}
