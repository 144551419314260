.popup_background {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.popup_window {
  width: 538px;
  height: 600px;
  background: #ffffff;
  border-radius: 20px;
  padding: 35px;
}

.title {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-weight: 600;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 20px;
}

.img_title {
  display: flex;
  justify-content: center;
  font-size: 22px;
  font-weight: 300;
  padding-bottom: 30px;
}

.img {
  margin-top: -20px;
}

.close {
  position: absolute;
  right: 0px;
}
