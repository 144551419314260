.containerM {
  display: flex;
  flex-direction: column;
  padding: 25px;
}

.main_block_container {
  margin-top: 100px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 50px;
  height: 657px;

  background: linear-gradient(249.74deg, #ff6e05 -4.19%, #ffaa05 99.97%);
  border-radius: 32px;

  padding: 20px;
  z-index: 0;
}

.main_block_title {
  text-align: left;
  font-family: Comfortaa;
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0em;
  color: white;
}

.button_container {
}

.img_container {
  /* border: 1px solid #000; */
  height: 200px;
}

.phone {
  top: -120px;
  left: -60px;
  right: 0;
  margin: auto;
  width: 400px;
  position: absolute;
}

.functions_container {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.item_container {
  /* border: 1px solid #000; */
  width: 375px;
  display: grid;
  grid-template-columns: 100px auto;
  align-items: center;
}

.item_container > * {
  /* border: 1px solid #000; */
}

.item_number {
  font-family: Montserrat;
  font-size: 64px;
  font-weight: 600;
  line-height: 78px;
  letter-spacing: 0em;
  color: #ff6e05;
}

.item_title {
  /* border: 1px solid #000; */
  font-family: Comfortaa;
  font-size: 22px;
  font-weight: 700;
  /* line-height: 50px; */
  letter-spacing: 0em;
}

.item_text {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
}

.block_title {
  font-family: Comfortaa;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
