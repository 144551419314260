.popup_background {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  z-index: 1;
}

.popup_window {
  width: max-content;
  max-width: 438px;
  height: max-content;
  max-height: 600px;
  background: #ffffff;
  border-radius: 20px;
  padding: 25px;
}

.title {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 22px;
  font-weight: 600;
  /* border-bottom: 1px solid #E1E1E1; */
  padding-bottom: 10px;
  padding-left: 10px;
}

.services_container {
  height: 400px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  overflow-y: scroll;
  padding: 15px;
}

.services_container::-webkit-scrollbar {
  width: 3px;
}

/* Handle */
.services_container::-webkit-scrollbar-thumb {
  background: #ff8a35;
  border-radius: 2px;
}

.tabs_block {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  color: #282828;
  font-size: 16px;
  font-weight: 400;
}

.tabs {
  display: flex;
  width: -webkit-fill-available;
  margin: 15px;
}

.tab {
  width: 100%;
  margin-inline: 5px;
  padding: 2px 10px;
  border-radius: 25px;
  background: #F4F5F7;
}

.serviceBlock {
  color: #000000;
  font-size: 14px;
  font-weight: 400;
}

.serviceBlock .date {
  margin-left: 20px;
}

.serviceInfo {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 8px 18px;
  background: #F4F5F7;
  border-radius: 15px;
}

.serviceGroup {
  background: #FABF7D66;
}

.serviceInfo .serviceName {
  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;
}

.group_appointment_group__delete {
  position: absolute;
  display: flex;
  align-items: center;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  background: none;
}

.img_block {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.img_block .img img {
  width: 100%;
}

.img_block .img_title {
  font-size: 18px;
  text-align: center;
}

.img_block .title {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 22px;
  font-weight: 600;
  padding-bottom: 10px;
  padding-left: 10px;
}