.container {
  display: flex;
  flex-direction: column;
  margin: 23px auto;
  max-width: 680px;
  min-height: 300px;
  background: #ffffff;
  border-radius: 20px;
  padding: 45px;
}

.title {
  margin-bottom: 35px;
  font-size: 20px;
  font-weight: 700;
  line-height: 23.44px;
}

.list {
  width: 100%;
  max-height: 450px;
  padding: 0 40px;
  color: #6D6D6D;
  overflow-y: scroll;
}

/* width */
.list::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.list::-webkit-scrollbar-track {
  background: #f1f1f1;
  padding: 2px; 
}

/* Handle */
.list::-webkit-scrollbar-thumb {
  background: #ff8a35;
  border-radius: 2px;
}

/* Handle on hover */
.list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.info, .date {
  margin-bottom: 15px;
}

.date {
  font-size: 14px;
  font-weight: 500;
  line-height: 16.41px;
}

.info {
  display: flex;
}

.info .time {
  margin-right: 20px;
  font-size: 12px;
  font-weight: 400;
}

.info .message {
  color: #282828;
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
}

.container_mobile {
  margin: 0 10px;
  padding: 7px 0;
}

.container_mobile .list{
  margin: 0 10px;
  padding: 7px 0;
  height: calc( 100vh - 100px - 60px);
  max-height: max-content
}
