.popup_window {
  width: 420px;
  height: 600px;
  background: #ffffff;
  border-radius: 20px;
  padding: 35px;
  margin-inline: 20px ;
}

.titleBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.title {
  font-size: 24px;
  font-weight: 600;
}

.navigateButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 263px;
  height: 52px;
  margin: 15px auto 0;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 500;
}

.back {
  display: flex;
  align-items: center;
  
  font-size: 18px;
  font-weight: 400;
}

.back svg {
  margin-right: 20px;
}

.category_container {
  display: flex;
  align-items: center;
  column-gap: 12px;
  margin-top: 10px;
}

.addCategory {
  display: block;
  cursor: pointer;
  margin-top: 15px;
  margin-left: 5px;
  font-size: 14px;
  font-weight: 500;
  color: #FF8A35;
}
