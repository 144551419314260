.notifications {
  float: left;
  margin-right: 20px;
  background: none;
}

.notifications.active {
  animation: blink-animation 1.5s infinite; 
}

@keyframes blink-animation {
  0% {
    opacity: 1; 
  }
  50% {
    opacity: 0.7; 
  }
  100% {
    opacity: 1; 
  }
}