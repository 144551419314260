* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.sendMassage_container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  background: rgba(195, 197, 202, 0.5);
  height: 100vh;
}

.sendMassage_block {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  position: relative;
  z-index: 10;
  background-color: #fff;
  width: 380px;
  padding: 30px 40px 29px 46px;
  border-radius: 20px;
}
.sendMassage_title {
  font-size: 24px;
  line-height: 42px;
  margin-bottom: 18px;
}
.sendMassage_close {
  position: absolute;
  top: 13px;
  cursor: pointer;
  width: 19px;
  right: 13px;
}

.sms_data {
  /* border: 1px solid #000; */
  display: flex;
  justify-content: space-between;
  color: #979797;
  font-size: 14px;
}

.message_container {
  position: relative;
}

.sender {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #979797;
}
