* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.confirmationModal_container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  width: 100%;
  background: rgba(195, 197, 202, 0.5);
  height: 100vh;
}

.confirmationModal_block {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  position: relative;
  width: 443px;
  background-color: #fff;
  padding: 60px 0 50px 0;
  border-radius: 20px;
  z-index: 10;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.confirmationModal_title {
  font-size: 20px;
  max-width: 280px;
  margin: 0 auto;
  margin-bottom: 25px;
  text-align: center;
  color: #282828;
}
.confirmationModal_button {
  display: block;
  color: #fff;
  margin: 0 auto;
  border: none;
  background: linear-gradient(88.45deg, #595f63 -33.98%, #282828 112.64%);
  border-radius: 10px;
  width: 274px;
  padding: 20px 0;
  cursor: pointer;
  font-size: 20px;
}

.close_img {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 20px;
  cursor: pointer;
}
