.clientsBlock_container {
  /* border: 1px solid #000; */
  width: 1200px;
  min-height: 700px;
  background-color: white;
  border-radius: 20px;
  padding: 30px;
}

.clientsBlock_container_header {
  /* border: 1px solid #b82a2a; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.clients_container {
  margin-top: 40px;
}
