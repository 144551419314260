.form_container {
  /* border: 1px solid #000; */
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  width: 450px;
  padding: 12px;
}

.outer_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.inner_form_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 19px;
  padding: 40px;
  background: #ffffff;
  border-radius: 20px;
  z-index: 4;
}

.title {
  /* border: 1px solid #000; */
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #282828;
}

.photo_container {
  display: flex;
  justify-content: center;
}

.logo_container {
  margin-top: -80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttons_container {
  z-index: 2;
}
