.profile_photo_container {
  position: relative;
  /* border: 1px solid rgb(183, 23, 23); */
  width: fit-content;
}

.photo {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid rgb(255, 158, 78); */
  border-radius: 50%;
  overflow: hidden;
  width: 150px;
  height: 150px;
}

.edit {
  /* border: 1px solid rgb(14, 109, 168); */
  position: absolute;
  bottom: 7px;
  right: 7px;
}
