* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.editClient_container {
  font-family: 'Roboto';
  font-style: normal;
  padding: 32px;
}
.editClient_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 31px;
}
.editClient_header img {
  width: 31px;
  cursor: pointer;
}
.editClient_title {
  font-weight: 700;
  font-size: 20px;
  line-height: 42px;
  color: #282828;
  flex: 1;
  text-align: center;
}
.editClient_input_wrapper {
  margin-bottom: 20px;
}

.editClient_textarea {
  height: 176px;
}
.editClient_button {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  background-color: #ff8a35;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 19px 23px;
  cursor: pointer;
  margin-bottom: 20px;
  width: 90%;
  font-size: 16px;
}

.editClient_form {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
