.container {
  width: 415px;
  min-height: 196px;
  background: #ffffff;
  border-radius: 20px;
  padding: 45px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.title {
  text-align: center;
  font-weight: 700;
  font-size: 24px;
}

.cahnnel_subtitle {
  text-align: center;
  font-weight: 300;
  font-size: 14px;
}

.title_container {
  position: relative;
  width: 100%;
  height: 40px;
  display: flex;

  justify-content: space-between;
  align-items: center;
}

.toggle {
  position: relative;
  top: 5px;
}

.flex_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
