.container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 354px;
  min-height: 610px;
  background: #ffffff;
  border-radius: 20px;
  padding: 15px;
}

.title {
  display: flex;
  align-items: center;
  /* border-bottom: 1px solid rgb(218, 218, 218); */
  height: 40px;
  color: #282828;
  font-weight: 600;
  font-size: 18px;
  padding-left: 15px;
}

.day_container {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  /* width: 323px; */
  height: 50px;
  background: #ff8a35;
  border-radius: 8px;
}

.day_container_closed {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  /* width: 323px; */
  height: 50px;
  background: #f4f5f7;
  border-radius: 8px;
}

.day_name {
  /* border: 1px solid #000; */
  display: flex;
  align-items: center;
  padding-left: 15px;
  color: #ffffff;
}

.day_name_closed {
  /* border: 1px solid #000; */
  display: flex;
  align-items: center;
  padding-left: 15px;
  color: #282828;
}

.arrow {
  /* border: 1px solid #000; */
  display: flex;
  align-items: center;
  padding-right: 15px;
}

.main_day_container {
  /* border: 1px solid #000; */
}

.add_interval {
  color: #ff8a35;
}

.interval_selector {
  background: #f4f5f7;
  border-radius: 10px;
  outline: none;
  width: 126px;
  height: 40px;
  border: none;
  padding-left: 30px;
  font-size: 16px;
}

.interval_selector_container {
  position: relative;
}

.interval_selector_label {
  /* border: 1px solid #000; */
  position: absolute;
  top: -20px;
  left: 0px;
  color: #545454;
}

.interval {
  display: flex;
  column-gap: 10px;
  padding-top: 25px;
}

.delete_interval_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.intervals {
  padding: 15px;
}

.add_interval {
  padding-top: 10px;
}

.days_container {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding-top: 15px;
}

.mobile_container {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 20px;
}

.buttons_container {
  position: fixed;
  width: 100%;
  padding: 15px;
  padding-top: 0px;
  bottom: 0;
  left: 0;
  /* border: 1px solid #000; */
  background-color: #ffffff;
}

.empty_block {
  height: 125px;
}
