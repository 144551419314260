.outer_container {
  display: flex;
  justify-content: center;
}

.main_container {
  display: flex;
  /* border: 1px solid #000; */
  column-gap: 15px;
  padding-top: 15px;
}

.left_side {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  /* border: 1px solid rgb(209, 43, 43); */
  height: auto;
}

.right_side {
  /* border: 1px solid rgb(9, 147, 131); */
  height: auto;
}

.shedule {
  width: 354px;
  height: 100%;
  background: #ffffff;
  border-radius: 20px;
}
