.container {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
}

.steps {
  margin-top: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 15px;
}
