.container {
  width: 467px;
  height: max-content;
  left: 17px;
  top: 116px;
  background: #fffefe;
  border-radius: 20px;
  /* border: 1px solid #000; */
}

.call_button {
  display: flex;
  justify-content: end;
  /* border: 1px solid #000; */
  padding: 15px;
}

.inner_container {
  /* border: 1px solid #000; */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
.avatar_container {
  display: flex;
  justify-content: center;
  /* border: 1px solid #000; */
}

.avatar {
  width: 250px;
}

.name {
  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.about {
  display: flex;
  justify-content: center;
  padding-top: 15px;
  text-align: center;
  padding-left: 80px;
  padding-right: 80px;
}

.hint {
  display: flex;
  justify-content: center;
  padding-top: 8px;
  font-weight: 500;
}

.button_container {
  padding-top: 8px;
  padding-bottom: 16px !important;
  display: flex;
  justify-content: center;
}

.social_links {
  display: flex;
  justify-content: center;
  column-gap: 15px;
  padding-top: 15px;
}

.container_mobile {
  width: 100vw;
  height: 100vh;

  background: #fffefe;
}

.button_record{
  display: flex;
  flex-direction: column;
}