.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main_block_container {
  margin-top: 90px;

  position: relative;
  display: grid;
  grid-template-columns: auto;
  align-items: center;
  width: 1280px;
  height: 537px;

  background: linear-gradient(249.74deg, #ff6e05 -4.19%, #ffaa05 99.97%);
  border-radius: 32px;
  padding: 75px;
}

.main_block_title {
  /* border: 1px solid #000; */
  position: relative;
  top: 30px;
  width: 713px;
  font-family: Comfortaa;
  font-size: 30px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
}

.main_block_button_container {
  /* border: 1px solid #000; */
}

.phone {
  position: absolute;
  right: 150px;
  width: 570px;
}

.item_container {
  /* border: 1px solid #000; */
  width: 520px;
  display: grid;
  grid-template-columns: 100px auto;
}

.item_container > * {
  /* border: 1px solid #000; */
}

.item_number {
  font-family: Montserrat;
  font-size: 64px;
  font-weight: 600;
  line-height: 78px;
  letter-spacing: 0em;
  color: #ff6e05;
}

.item_title {
  font-family: Comfortaa;
  font-size: 36px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0em;
}

.item_text {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
}

.functions_container {
  margin-top: 100px;
  display: grid;
  grid-template-columns: auto auto;
  row-gap: 25px;
}

.functions_container > * {
  /* border: 1px solid #000; */
}

.block_title {
  margin-top: 80px;
  font-family: Comfortaa;
  font-size: 36px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0em;
}
