.container {
  width: 986px;
  height: 190px;
  background: #ffffff;
  border-radius: 20px;
  z-index: 0;
}

.mobile_container {
  width: 100%;
  height: 190px;
  background: #ffffff;
  border-radius: 20px;
  z-index: 1;
}

.title {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  height: 60px;
  border-bottom: 1px solid rgb(228, 228, 228);
}

.link {
  padding-top: 25px;
  /* border: 1px solid #000; */
  display: flex;
  justify-content: center;
}

.link_inner_container {
  /* border: 1px solid #000; */
  display: flex;
  column-gap: 10px;
}

.copybox {
  /* border: 1px solid #000; */
}

.description {
  padding-top: 15px;
  display: flex;
  justify-content: center;
  color: #6d6d6d;
}

.edit {
  position: absolute;
  right: 20px;
}

.link_container {
  /* border: 1px solid #000; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 410px;
  border-radius: 8px;
  background-color: #f4f5f7;
}

/* if screen widht lower then 1025px */

@media (max-width: 1025px) {
  .link_container {
    width: 330px;
  }
}
