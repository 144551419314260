.form_container {
  /* border: 1px solid #000; */
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  width: 450px;
  padding: 12px;
  z-index: 2;
}

.outer_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.mobile_form_container {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  padding: 13px;
  margin-top: 40px;
}

.logo_container {
  display: flex;
  justify-content: center;
  align-items: center;
}
