.weekChanger_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 53px;
  width: 100%;
  border-radius: 8px;
  background-color: #ffffff;

  padding-left: 22.5px;
  padding-right: 22.5px;
}

.calendar_container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.calendar_header {
  margin-top: 10px;

  display: flex;
  width: 982px;
  background-color: #ffff;
}

.calendar_inner_container {
  position: relative;
  display: flex;

  width: 982px;
  height: 502px;
  background-color: #ffffff;
  border-radius: 8px;
  overflow: scroll;
  overflow-x: hidden;
}

.times_container {
  /* position: fixed; */
}
.time_container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d0d5d9;
  border-bottom: none;
  border-right: none;

  width: 70px;
  height: 89px;
  font-weight: 500;
}

.daySlots_container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.time_slot_container {
  border: 1px solid #d0d5d9;
  border-right: none;
  border-bottom: none;

  width: 130px;
  height: 89px;
  flex-shrink: 0;
}

.day_title_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #d0d5d9;
  border-top: none;
  border-bottom: none;

  border-right: none;
  width: 130px;
  height: 50px;
}

.number {
  font-size: 18px;
  text-align: center;
}

.week_day {
  font-size: 14px;
  text-align: center;
}

.time_slot_header {
  width: 70px;
}

.line {
  position: absolute;
  height: 2px;
  width: 1260px;
  background-color: rgb(0, 166, 255);
}

.appointment_container {
  position: absolute;
  /* border: 1px solid #000; */

  z-index: 1;
  width: 130px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #f8faff;

  border-bottom: 2px solid #ff8a35;
  border-radius: 2px;
}

.time_range {
  text-align: center;
  font-size: 10px;
  color: #282828;
}

.service_name {
  width: 130px;
  white-space: nowrap;

  text-align: center;
  font-size: 12px;
  font-weight: bold;
  color: #282828;
  text-overflow: ellipsis;
  overflow: hidden;
}

.weekend_container {
  width: 982px;
  display: flex;
  height: 22px;
  background: #ffffff;
}

.checkbox {
  width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #979797;
  border: 1px solid white;
}

.weekend_title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  font-size: 12px;
  color: white;
  background-color: #979797;
}
