.menu_container {
  position: fixed;
  bottom: 0px;
  width: 100%;
  /* border: 1px solid #000; */
  height: 70px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #f8f8f8;
}

.link_container {
  /* border: 1px solid green; */
  width: fit-content;
  height: fit-content;
  text-decoration: none;
}

.icon_container {
  /* border: 1px solid orangered; */
  display: flex;
  justify-content: center;
}

.icon {
  /* border: 1px solid blue; */
}
.title {
  font-size: 12px;
  text-align: center;
  color: #656565;
  padding-top: 4px;
}
