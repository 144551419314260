.popup_background {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.popup_window {
  width: 438px;
  height: 600px;
  background: #ffffff;
  border-radius: 20px;
  padding: 25px;
}

.title {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 22px;
  font-weight: 600;
  /* border-bottom: 1px solid #E1E1E1; */
  padding-bottom: 10px;
  padding-left: 10px;
}

.close {
  /* position: absolute;
    right: 0px; */
}

.services_container {
  /* margin-top: 15px; */
  height: 400px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  overflow-y: scroll;
  padding: 15px;
  /* border: 1px solid #000; */
}

/* width */
.services_container::-webkit-scrollbar {
  width: 3px;
}
/* Track */
.services_container::-webkit-scrollbar-track {
  /* background: #f1f1f1; */
}
/* Handle */
.services_container::-webkit-scrollbar-thumb {
  background: #ff8a35;
  border-radius: 2px;
}
/* Handle on hover */
.services_container::-webkit-scrollbar-thumb:hover {
  /* background: #555; */
}

.service_name {
  font-size: 20px;
  font-weight: bold;
}

.property {
  font-weight: bold;
}

.service_description {
  /* border: 1px solid #000; */
  border: none;
  outline: none;
  height: 300px;
  overflow-y: auto;
  font-family: Roboto;
  font-size: 16px;
  resize: none;
}

.category {
  /* border: 1px solid #000; */
  background-color: #d9d9d966;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;

  width: 100px;
  height: 25px;
  padding-left: 5px;
  padding-right: 5px;
}

.categories_container {
  /* border: 1px solid #000; */
  /* padding-top: 15px; */
  padding-left: 15px;
  padding-right: 15px;

  display: flex;
  flex-direction: column;
  column-gap: 15px;
  row-gap: 10px;
}
