.container {
  width: 895px;
  height: 300px;
  background: #ffffff;
  border-radius: 20px;
  /* border: 1px solid #000; */
  padding: 20px;
}

.container_mobile {
  width: 100vw;
  background: #ffffff;
  border-radius: 20px;
  /* border: 1px solid #000; */
  padding: 20px;
}

.line1 {
  display: flex;
  justify-content: space-between;
  /* border: 1px solid #000; */
  height: fit-content;
}

.line1_mobile {
  display: flex;
  justify-content: center;
  /* border: 1px solid #000; */
  height: fit-content;
}

.title {
  font-size: 22px;
  font-weight: 500;
}

.filter {
}

.timeslot_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 125px;
  height: 49.41px;
  border-radius: 8px;
  border: 1px solid #dadada;
  color: #282828;
  font-weight: 500;
}
.timeslot_container:hover {
  border: 1px solid #ff8a35;
}

.timeslots {
  /* border: 1px solid #000; */
  display: flex;
  flex-wrap: wrap;
  row-gap: 5px;
  column-gap: 10px;
  height: 200px;
  overflow-y: scroll;
  margin-top: 20px;
}

.timeslots_mobile {
  /* border: 1px solid #000; */
  /* display: flex;
  flex-wrap: wrap;
  row-gap: 10px; */

  display: grid;
  grid-template-columns: auto auto auto;

  column-gap: 10px;
  row-gap: 10px;
  /* height: 240px; */
  margin-top: 20px;
}

/* width */
.timeslots::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.timeslots::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.timeslots::-webkit-scrollbar-thumb {
  background: #ff8a35;
  border-radius: 2px;
}

/* Handle on hover */
.timeslots::-webkit-scrollbar-thumb:hover {
  background: #555;
}
