* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.confirmationModal_background {
  position: absolute;
  background: rgba(131, 130, 130, 0.5);
  top: 0;
  width: 100%;
  height: 100vh;
}
.confirmationModal_block {
  z-index: 1;
  font-family: 'Roboto';
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #ffffff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 25px 31px 57px;
}
.confirmationModal_title {
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  color: #000000;
  margin-bottom: 37px;
}
.confirmationModal_button__true,
.confirmationModal_button__false {
  background-color: #ff8a35;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 19px 23px;
  cursor: pointer;
  width: 320px;
  font-size: 18px;
  line-height: 21px;
}
.confirmationModal_button__false {
  margin-top: 12px;
  background: #a4a4a4;
}
