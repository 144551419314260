.popup_background {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  z-index: 10;
}

.popup_window {
  width: 538px;
  height: fit-content;
  background: #ffffff;
  border-radius: 20px;
  padding: 35px;
  z-index: 10;
}

.close {
  position: absolute;
  right: 0px;
}

.text {
  color: #282828;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
}

.close_container {
  display: flex;
  align-items: center;
  justify-content: end;
  position: relative;
  font-size: 21px;
  font-weight: 500;
}

.img_block {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.img_block .img img {
  width: 100%;
}

.img_block .img_title {
  font-size: 20px;
}

.title {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 22px;
  font-weight: 600;
  padding-bottom: 10px;
  padding-left: 10px;
}