.container {
  color: #6D6D6D;;
}

.input_block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.input_block__name {
  min-width: 100px;
  margin-right: 20px;
  text-wrap: nowrap;
}

.errors {
  display: flex;
  justify-content: center;
  color: red;
  padding: 10px;
}