.mobile_container {
  width: 100%;
  height: 180px;
  background: #ffffff;
  border-radius: 20px;
  z-index: 1;
}

.title {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  height: 60px;
  border-bottom: 1px solid rgb(228, 228, 228);
}

.link {
  padding-top: 25px;
  /* border: 1px solid #000; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.link_inner_container {
  margin-top: -20px;
  /* border: 1px solid rgb(222, 35, 35); */
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  width: 100%;
  padding: 15px;
}

.copybox {
  /* border: 1px solid #000; */
}

.description {
  font-size: 12px;
  padding-top: 0px;
  display: flex;
  justify-content: center;
  color: #6d6d6d;
}

.edit {
  position: absolute;
  right: 20px;
}

.link_container {
  border: 2px solid rgb(22, 152, 164);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 310px;
  height: 40px;
  border-radius: 8px;
  background-color: #f4f5f7;
}
