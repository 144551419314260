.container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  /* border: 1px solid #000; */
}

.logo {
  border: 1px solid #000;
}

.menu_container {
  position: absolute;
  right: 0px;
  top: 50px;
  width: 180px;
  height: 250px;
  border-radius: 32px;
  background-color: #282828;
  padding: 20px;
  z-index: 2;
}

.menu_link {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgb(227, 227, 227);
  height: 40px;
}

.title {
  font-family: Comfortaa;

  color: white;
  font-weight: 500;
  font-size: 16px;
  text-decoration: none;
}

.logo_container {
  /* border: 1px solid #000; */
  display: flex;
  align-items: center;
}

.ua_flag {
  position: relative;
  bottom: 15px;
}
