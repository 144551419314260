.input {
  background: #f4f5f7;
  border-radius: 10px;
  outline: none;
  width: 100%;
  border: none;
  padding-left: 21px;
  padding-top: 5px;
  font-size: 14px;
}
