.container {
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  padding-top: 25px;
  font-weight: bold;
}
