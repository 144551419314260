.stats_period_selector_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 53px;
  width: 982px;
  border-radius: 20px;
  background-color: #ffffff;

  padding: 0px 20px 0px 20px;
}

@media (width<1024px) {
  .stats_period_selector_container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 53px;
    width: auto;
    border-radius: 20px;
    background-color: #ffffff;

    padding: 0px 20px 0px 20px;
  }

  .title {
    display: none;
  }
}
