.container {
  display: flex;
  justify-content: center;
  column-gap: 15px;
  padding-top: 15px;

  /* border: 1px solid #000; */
}

.right_side {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.popup_background {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.popup_window1 {
  width: 300px;
  height: 180px;
  background: #ffffff;
  border-radius: 20px;
  padding: 15px;
}

.popup_title {
  font-size: 22px;
  font-weight: bold;
}

.popup_phone {
  font-size: 18px;
  margin-top: 20px;
  font-weight: bold;
}

.img_container {
  margin-top: 100px;
  /* border: 1px solid #000; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.nodata_title {
  margin-top: 20px;
  padding-left: 20px;
  /* border: 1px solid #000; */
}
