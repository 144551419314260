.header_container_desktop {
  margin-top: 30px;
  width: 1266px;
  /* border: 1px solid #000; */
  display: flex;
  justify-content: space-between;
  height: 70px;
}

.links {
  display: flex;
  align-items: center;
  column-gap: 60px;
  font-size: 18px;
}

.link {
  font-family: Comfortaa;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;

  color: black;
  text-decoration: none;
}

.link:hover {
  color: orange;
  cursor: pointer;
}

.logo_container {
  /* border: 1px solid #000; */
  display: grid;
}

.logo_title::before {
  content: url('../../Images/ukraine.png');
  padding-right: 5px;
}

.logo_title {
  /* border: 1px solid #000; */
  text-align: right;
  font-family: 'Comfortaa';
  color: gray;
}

.rightBlock {
  display: flex;
  align-items: center;
}