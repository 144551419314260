.container {
  display: flex;
  height: 420px;
  background-color: #282828;
  padding: 86px 86px 78px;
}

.left_side {
  /* border: 1px solid #efefef; */
  width: 500px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  color: white;
  /* border: 1px solid white; */
}

.right_side {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: end;
  row-gap: 58px;
}

.right_side > div {
  display: flex;
  align-items: end;
  flex-direction: column;
}

.contact_with_messenger {
  font-weight: 500;
  font-size: 20px;
  line-height: 1.4;
  color: #fefefe;
  margin-bottom: 23px;
  max-width: 297px;
}

.messengers {
  display: flex;
  align-items: center;
  gap: 10px;
}

.messengers img {
  cursor: pointer;
}

.text {
  color: white;
  width: 470px;
}

.mail {
  color: white;
  cursor: pointer;
  margin-bottom: 13px;
}

.bottom_line {
  display: flex;
  justify-content: center;
  color: white;
  padding-bottom: 40px;
  background-color: #282828;
}

.social_links img {
  cursor: pointer;
}

.stores {
  padding-top: 20px;
  display: flex;
  /* border: 1px solid #000; */
}
