.container {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
}

.functions {
  justify-content: center;
  margin-top: 50px;
  display: grid;
  grid-template-columns: 150px 150px;
  row-gap: 25px;
  column-gap: 25px;
}
