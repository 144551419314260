.inputs_title_container {
  /* border: 1px solid #000; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  color: #282828;
  line-height: 42px;
}

.input_container {
  position: relative;
  /* border: 1px solid #000; */
  width: 100%;
}

.input {
  background: #f4f5f7;
  border-radius: 10px;
  outline: none;
  width: 100%;
  height: 45px;
  border: none;
  padding-left: 21px;
  padding-top: 5px;
  font-size: 18px;
}

.input_label {
  position: absolute;
  /* border: 1px solid #000; */
  /* z-index: 1; */
  left: 22px;
  top: 4px;
  font-size: 10px;
  color: #919191;
}

.input_description {
  /* border: 1px solid #000; */
  font-weight: 400;
  font-size: 10px;
  color: #a3a7ae;
  padding-left: 21px;
  padding-top: 3px;
}

.below_input_text {
  /* border: 1px solid #000; */
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
}

.selected_word {
  /* border: 1px solid #000; */
  height: fit-content;
  color: #ff8a35;
  padding-left: 7px;
  text-decoration: underline;
}

.selected_word:hover {
  /* border: 1px solid #000; */
  height: fit-content;
  color: #fb6900;
  padding-left: 7px;
  text-decoration: underline;
}

.button {
  height: 55px;
  width: 100%;
  background: #ff8a35;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 700;
  border: none;
  color: #ffff;
}

.button:hover {
  height: 55px;
  width: 100%;
  background: #ff964b;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 700;
  border: none;
  color: #ffff;
}
.button_black {
  height: 55.36px;
  width: 100%;
  background: linear-gradient(88.45deg, #595f63 -33.98%, #282828 112.64%);
  border-radius: 10px;
  font-size: 18px;
  font-weight: 700;
  border: none;
  color: #ffff;
}

.button_black:hover {
  height: 55.36px;
  width: 100%;
  background: linear-gradient(88.45deg, #595f63 -33.98%, #595959 112.64%);
  border-radius: 10px;
  font-size: 18px;
  font-weight: 700;
  border: none;
  color: #ffff;
}

.button:disabled,
.button_black:disabled {
  background: #D8D8D8;
}

.background_container {
  background-color: #ffffff;
  width: 100vw;
  height: 100vh;
}

.circle1 {
  position: absolute;
  top: 200px;
  left: 80px;

  width: 166px;
  height: 166px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #b6c1f5 69.27%,
    rgba(0, 0, 0, 0) 100%
  );
  mix-blend-mode: normal;
  opacity: 0.2;
  border-radius: 486.053px;
}

.circle2 {
  position: absolute;
  left: 233px;
  bottom: 118px;

  width: 103px;
  height: 103px;

  background: radial-gradient(
    50% 50% at 50% 50%,
    #b6c1f5 69.27%,
    rgba(0, 0, 0, 0) 100%
  );
  mix-blend-mode: normal;
  opacity: 0.2;
  border-radius: 486.053px;
}

.circle3 {
  position: absolute;
  right: 233px;
  top: 150px;

  width: 103px;
  height: 103px;

  background: radial-gradient(
    50% 50% at 50% 50%,
    #b6c1f5 69.27%,
    rgba(0, 0, 0, 0) 100%
  );
  mix-blend-mode: normal;
  opacity: 0.2;
  border-radius: 486.053px;
}

.circle4 {
  position: absolute;
  right: 118px;
  bottom: 44px;

  width: 300px;
  height: 300px;

  background: radial-gradient(
    50% 50% at 50% 50%,
    #b6c1f5 69.27%,
    rgba(0, 0, 0, 0) 100%
  );
  mix-blend-mode: normal;
  opacity: 0.2;
  border-radius: 486.053px;
}

.agree_with_rules_container {
  /* border: 1px solid #000; */
  display: flex;
}

.agree_with_rules_string {
  /* border: 1px solid #000; */
  height: 40px;
  font-size: 14px;
  font-weight: 400;
  padding-left: 10px;
}

.checkbox {
  /* border: 1px solid #000; */
  padding-top: 4px;
}

.white_block {
  width: 292px;
  height: 157px;
  background: #ffffff;
  border-radius: 20px;
}

.raw_title {
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-size: 12px;
  color: #6d6d6d;
}

.raw_value {
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  color: #282828;
}

.profile_raw_container {
  margin-top: 15px;
}

.selector {
  -webkit-appearance: none;
  background: #f4f5f7;
  border-radius: 10px;
  outline: none;
  width: 100%;
  height: 45px;
  border: none;
  padding-left: 21px;
  padding-top: 5px;
  font-size: 18px;
}

.service_container {
  /* height: 60px; */
  border: 1px solid #d0d5d9;
  border-radius: 8px;
  padding: 6px 10px 0px 14px;
  margin-bottom: 10px;
}

.service_container_withInfo {
  position: relative;
  /* height: 60px; */
  border: 1px solid #d0d5d9;
  border-radius: 8px;
  padding-left: 14px;
  padding-right: 10px;

  padding-top: 6px;
  padding-bottom: 10px;
}

.service_info_img {
  position: absolute;
  width: 20px;
  top: 10px;
  right: 10px;
}

.service_description {
  color: #555;
}

.service_button {
  width: 100px;
  height: 40px;
  background-color: coral;
  color: #ffff;
  font-size: 14px;
  border-radius: 4px;
  font-weight: 200;
}

.service_container:hover {
  border: 1px solid #d0d5d9;
  border-radius: 8px;
  padding-left: 14px;
  padding-right: 10px;
  padding-top: 6px;
  border: 1px solid #ff8a35;
}

.service_line1 {
  /* border: 1px solid #000; */
  font-weight: 600;
  font-size: 18px;
}

.service_line2 {
  font-size: 13px;
  padding-top: 2px;
  padding-bottom: 10px;
}

.textarea {
  background: #f4f5f7;
  border-radius: 10px;
  outline: none;
  width: 100%;
  height: 170px;
  border: none;
  padding-left: 21px;
  padding-top: 10px;
  font-size: 18px;
}

.q {
  position: absolute;
  right: 10px;
  top: 12px;
  color: #282828;
  /* font-weight: 600; */
}

.social_network_container {
  /* border: 1px solid #000; */
  display: flex;
  column-gap: 10px;
}

.social_network_name {
  display: flex;
  align-items: center;
  /* border: 1px solid #000; */
}

.social_network_icon_container {
  display: flex;
  align-items: center;
  /* border: 1px solid #000; */
}

.desktop_profile_raw_container {
  display: flex;
  /* border: 1px solid #000; */
}

.desktop_profile_raw_container_title {
  /* border: 1px solid #000; */

  display: flex;
  align-items: center;
  width: 100px;
  font-size: 14px;
  color: #6d6d6d;
}

.desktop_profile_raw_value {
  /* border: 1px solid #000; */

  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 17px;
  color: #282828;
}

.desktop_profile_raw_container_title_textarea {
  /* border: 1px solid rgb(175, 22, 22); */
  width: 45px;
  font-size: 14px;
  color: #6d6d6d;
  width: 100px;
}

.desktop_profile_raw_value_textarea {
  /* border: 1px solid #000; */
  font-size: 16px;
  color: #282828;
  width: 450px;
  max-height: 190px;
  overflow: auto;
}

/* width */
.desktop_profile_raw_value_textarea::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.desktop_profile_raw_value_textarea::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.desktop_profile_raw_value_textarea::-webkit-scrollbar-thumb {
  background: #ff8a35;
  border-radius: 2px;
}

/* Handle on hover */
.desktop_profile_raw_value_textarea::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.editable_desktop_raw_container {
  display: flex;
  /* column-gap: 45px; */
}

.editable_desktop_raw_label {
  display: flex;
  justify-content: start;
  width: 100px;
  align-items: center;
  color: #6d6d6d;
  font-size: 14px;
}

.editable_desktop_raw_description_label {
  width: 100px;

  color: #6d6d6d;
  font-size: 14px;
}

/* width */
.textarea::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.textarea::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.textarea::-webkit-scrollbar-thumb {
  background: #ff8a35;
  border-radius: 2px;
}

/* Handle on hover */
.textarea::-webkit-scrollbar-thumb:hover {
  background: #555;
}
