
.plan_container {
  background-color: white;
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  row-gap: 15px;
  padding: 30px;
}

.plan_title {
  font-weight: 500;
  font-size: 25px;
  font-family: 'Comfortaa';
  font-style: normal;
}

.options {
  margin-top: 10px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}