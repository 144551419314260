.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1266px;
  height: 568px;
  /* border: 1px solid #000; */
}

.inner_container {
  /* border: 1px solid #000; */
}

.title {
  text-align: center;
  /* border: 1px solid #000; */
  font-size: 24px;
}

.functions {
  padding-top: 70px;
  display: flex;
  justify-content: center;
  column-gap: 50px;
}
