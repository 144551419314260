.service_info_container {
  /* border: 1px solid #000; */
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 12px;
}

.service_info {
  /* border: 1px solid rgb(126, 26, 26); */
  display: flex;
  flex-direction: column;
  align-self: center;
}

.service_info_title {
  color: #a3a7ae;
}

.service_info_value {
  color: #282828;
  font-weight: 600;
}

.service_info2 {
  display: flex;
  column-gap: 40px;
}
