.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  row-gap: 10px;
}

.sheduler_container {
  /* border: 1px solid rgb(119, 41, 41); */
  width: 100%;
  overflow: hidden;
  overflow-x: scroll;
}

.switcher {
  display: flex;
  column-gap: 20px;
  /* border: 1px solid #000; */
  justify-content: start;
  font-weight: bold;
  font-size: 20px;
  padding-bottom: 20px;
}
