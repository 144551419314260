.container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.text_container {
  padding: 30px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
